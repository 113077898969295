import React, { useState, useEffect } from 'react';

function FilePreviewer() {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      if (
        selectedFile.type.startsWith("image") ||
        selectedFile.type.startsWith("video") ||
        selectedFile.type === "application/pdf"
      ) {
        setPreview(URL.createObjectURL(selectedFile));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => setPreview(e.target.result);
        reader.readAsText(selectedFile);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {file && (
        <div style={{ marginTop: '20px' }}>
          <h3>File Preview:</h3>
          {file.type.startsWith("image") && (
            <img src={preview} alt="Preview" width="300" />
          )}
          {file.type.startsWith("video") && (
            <video controls width="300" src={preview} type={file.type}>
              Your browser does not support the video tag.
            </video>
          )}
          {file.type === "application/pdf" && (
            <iframe
              src={"https://iudev001.s3.us-east-1.amazonaws.com/public/productMedia/92b43b19-cd15-421d-bbfe-093621410274-Gateway-Church.pdf"}
              width="100%"
              height="500px"
              title="PDF Preview"
              type="application/pdf"
              frameBorder="0"
            ></iframe>
          )}
          {file.type.startsWith("text") && (
            <textarea readOnly value={preview} rows={10} cols={30} />
          )}
          {!file.type.startsWith("image") &&
            !file.type.startsWith("video") &&
            file.type !== "application/pdf" &&
            !file.type.startsWith("text") && (
              <p>File preview is not available for this file type.</p>
            )}
        </div>
      )}
    </div>
  );
}

export default FilePreviewer;