import React from 'react'
import { BarLoader} from 'react-spinners';

export default function BarLocalLoader(){
    return (
        <div style={{
            // width:"100vw",height:"100vh",
        // position:"fixed",top:"0",left:"0",
            // backgroundColor:"rgba(210, 210, 210, 0.6)",backdropFilter:"blur(15px)",
            zIndex:"50"}} className={`flex justify-center items-center m-10`}
        >

          <BarLoader size={15} color="#1CBAAB"></BarLoader>

        </div>
    );
}

