// import {
//     getCompanyCookie,
//     setOnBoardCookie,
//     removeOnBoardCookie,
//   } from "auth/userCookies";
import { getOnBoardCookie, removeOnBoardCookie, removeToken, setOnBoardCookie, setToken } from "../auth/cookies";
  import firebase from "../auth/firebaseConfig"
import { toastError } from "./Toasters";
//   import { toastError } from "@/Components/Toasters";


  
  // const BASE_URL = 'https://api.dev.futrconnect.com/v1/'
  const BASE_URL = process.env.REACT_APP_BASE_URL
  // const BASE_URL = 'http://44.199.133.157:8090/v1/'

 

  // export async function tokenValidator() {
  //   var token = await getOnBoardCookie();
  //   if (token) {
  //     function parseJwt() {
  //       if (!token) {
  //         return;
  //       }
  //       const base64Url = token.split(".")[1];
  //       const base64 = base64Url.replace("-", "+").replace("_", "/");
  //       return JSON.parse(window.atob(base64));
  //     }
  //     var user = parseJwt();
  //     if (user.exp * 1000 < Date.now()) {
  //       firebase.auth().onAuthStateChanged(function (currentUser) {
  //         if (currentUser) {
  //           firebase
  //             .auth()
  //             .currentUser.getIdToken(true)
  //             .then((newToken) => {
  //               removeToken();
  //               removeOnBoardCookie();
  //               setToken(newToken);
  //               setOnBoardCookie(newToken)
  //               return newToken;
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //         }
  //       });
  //     } else {
  //       return token;
  //     }
  //   } else {
  //     return null;
  //   }
  // }


export async function tokenValidator(){
  const token = await getOnBoardCookie();
  if (!token) {
      return null;
  }

  function parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }

  const user = parseJwt(token);
  if (user.exp * 1000 < Date.now()) {
      return new Promise((resolve, reject) => {
          firebase.auth().onAuthStateChanged(async function (currentUser) {
              if (currentUser) {
                  try {
                      let newToken = await firebase.auth().currentUser.getIdToken(true);
                      removeToken();
                removeOnBoardCookie();
                setToken(newToken);
                setOnBoardCookie(newToken)
                      
                      resolve(newToken);
                  } catch (err) {
                      console.log(err);
                      reject(err);
                  }
              } else {
                  resolve(null);
              }
          });
      });
  } else {
      return token;
  }
  }

  export async function getMethod(url) {
    const token = await tokenValidator();
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");
  
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      try {
        const response = await fetch(BASE_URL + url, requestOptions);
        const data = await response.json();
        if (data.status === true) {
          return data;
        } else {
        //   toastError(data.message);
        console.log("DATA MESSAGE: ", data.message)
          return response;
        }
      } catch (e) {
        // toastError(e.message);
        console.log("ERROR MESSAGE: ", e.message)
        return e.message;
      }
    } else {
      return false;
    }
  }
  export async function getMethodTokenless(url) {
     
      try {
        const response = await fetch(BASE_URL + url);
        const data = await response.json();
        if (data.status === true) {
          return data;
        } else {
        //   toastError(data.message);
        console.log("DATA MESSAGE: ", data.message)
          return response;
        }
      } catch (e) {
        // toastError(e.message);
        console.log("ERROR MESSAGE: ", e.message)
        return e.message;
      }
    
  }
  
  export async function postMethod(url, payload) {

    console.log("PAYLOAD: ", payload)
   
    const token = await tokenValidator();
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json")
      // type !== "formData" && myHeaders.append("Content-Type", "application/json");
  
      // var raw;
      // type === "formData" ? (raw = payload) : (raw = JSON.stringify(payload));
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
        redirect: "follow",
      };

      try {
        const response = await fetch(BASE_URL + url, requestOptions);
        const data = await response.json();

        if (data.status === true) {
          return data;
        } else {
          toastError(data.message);
          return response;
        }
      } catch (e) {
        toastError(e.message);
        return e.message;
      }
    } else {
      return false;
    }
  }
  
  export async function postMethodTokenless(url, payload,token) {

    console.log("PAYLOAD: ", payload)
   
      var myHeaders = new Headers();
     
      myHeaders.append("Content-Type", "application/json")
      if(token !== undefined && token !==null && token){
        myHeaders.append("Authorization", "Bearer " + token) 
      }
      // type !== "formData" && myHeaders.append("Content-Type", "application/json");
  
      // var raw;
      // type === "formData" ? (raw = payload) : (raw = JSON.stringify(payload));
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
        redirect: "follow",
      };

      try {
        const response = await fetch(BASE_URL + url, requestOptions);
        const data = await response.json();

        if (data.status === true) {
          return data;
        } else {
          toastError(data.message);
          return response;
        }
      } catch (e) {
        toastError(e.message);
        return e.message;
      }
    
  }
  
  export async function patchMethod(url, payload, type) {
    
    const token = await tokenValidator();
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");
    
     var raw = JSON.stringify(payload);
  
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(BASE_URL + url, requestOptions);
        const data = await response.json();
        if (data.status === true) {
          return data;
        } else {
          toastError(data.message);
          return response;
        }
      } catch (e) {
        toastError(e.message);
        return e.message;
      }
    } else {
      return false;
    }
  }

  export async function patchFormMethod(url, formdata) {

    const token = await tokenValidator();

    if (token) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }

  }
  export async function postFormMethod(url, formdata) {

    console.log("FORMDATA: ", formdata)

    const token = await tokenValidator();

    if (token) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status === true) {
        return data;
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }

  }
  
  export async function deleteMethod(url) {
    const token = await tokenValidator();
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json");
  
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };
      try {
        const response = await fetch(BASE_URL + url, requestOptions);
        const data = await response.json();
        if (data.status === true) {
          return data;
        } else {
          // toastError(data.message);
          return data.message;
        }
      } catch (e) {
        // toastError(e.message);
        return e.message;
      }
    } else {
      return false;
    }
  }