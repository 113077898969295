import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { postMethod, postMethodTokenless } from "../../utils/apiMethod";
import { toastError, toastSuccess } from "../../utils/Toasters";
import HeaderMobileMode from "./HeaderMobileMode";
import Header from "./NewHeader";

function ResetPassword() {
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [params] = useSearchParams();
  let activeToken = params.get('token')
  const navigate = useNavigate()

  const SubmitHandler = (e) => {
console.log("Inside submit handler")
    e.preventDefault();
    if(password === confirmPassword){
        console.log("if password")
        var raw = JSON.stringify({
            token : activeToken,
            newPassword: password
        })
    
        postMethodTokenless(`auth/resetEmailPassword`, raw)
        .then((res) => {
            console.log("API response : ", res)
        //   setLoading(false);
          if (res) {
            // setLoading(false);
            console.log("RESPONSE : ", res);
            if(res.status === true){
              toastSuccess(res.message);
              setTimeout(()=> navigate("/"), 3000) 
            }
            else{
              toastError(res.message)
            }
          } else {
            toastError(res);
          }
        })
        .catch((error) => {
        //   setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    }else{
        toastError("Passwords do not match!")
    }
   

}
  return (
    <div className="flex flex-col bg-white h-screen gap-10">
      {/* Header */}
      <div className="bg-white w-full">
        <div className="flex">
          <div className="w-1/4 bg-dark-blue-1 h-3"> </div>
          <div className="w-1/4 bg-aqua-1 h-3"> </div>
          <div className="w-1/4 bg-sea-green h-3"> </div>
          <div className="w-1/4 bg-yellow-1 h-3"> </div>
        </div>
        <div className="flex justify-center mt-3 mb-5">
          <div className="flex justify-between items-center w-10/12">
            <div className="">
              <img src="assets/FutrLogo.png" alt="" className="h-24" />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto w-3/5">
        <form className="flex flex-col bg-blue-light rounded-md p-10 gap-5" onSubmit={SubmitHandler}>
          <div className="flex flex-col justify-center items-center gap-3">
            <div className="text-2xl font-semi-bold">Reset Password</div>

            <div>Create a password for your profile</div>
          </div>

          <div className="flex flex-col w-1/2 m-auto gap-5">
            <div className="flex flex-col m-auto gap-2 w-full">
              <div>New Password</div>
              <div className="rounded-md border border-grey-7 flex justify-between items-center bg-white px-2">
                <input
                  className="w-full outline-none px-5 py-2.5 rounded-md"
                  placeholder="Enter Password"
                  type={hidePassword ? "password" : "text"}
                  required={true}
                  value={password}
                  onChange={(e)=> setPassword(e.target.value)}
                />
                <div
                  className="cursor-pointer"
                  onClick={() => setHidePassword(!hidePassword)}
                >
                  {hidePassword ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.99984 7.99998C9.99984 9.10455 9.10441 9.99998 7.99984 9.99998C6.89527 9.99998 5.99984 9.10455 5.99984 7.99998C5.99984 6.89541 6.89527 5.99998 7.99984 5.99998C9.10441 5.99998 9.99984 6.89541 9.99984 7.99998Z"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.00013 3.33331C5.01504 3.33331 2.48819 5.29523 1.63867 7.99996C2.48817 10.7047 5.01504 12.6666 8.00014 12.6666C10.9852 12.6666 13.5121 10.7047 14.3616 8C13.5121 5.29526 10.9852 3.33331 8.00013 3.33331Z"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 2L14 14M6.56285 6.6091C6.21442 6.96903 6 7.45948 6 8C6 9.10457 6.89543 10 8 10C8.54832 10 9.0451 9.77935 9.4064 9.42199M4.33333 4.43143C3.06713 5.26689 2.10269 6.52263 1.63867 7.99998C2.48817 10.7047 5.01504 12.6667 8.00014 12.6667C9.32607 12.6667 10.5616 12.2796 11.5999 11.6123M7.33333 3.36626C7.55265 3.34448 7.77509 3.33333 8.00013 3.33333C10.9852 3.33333 13.5121 5.29528 14.3616 8.00002C14.1744 8.59598 13.9058 9.15588 13.5688 9.66667"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col m-auto gap-2 w-full">
              <div>Confirm Password</div>
              <div className="rounded-md border border-grey-7 flex justify-between items-center bg-white px-2">
                <input
                  className="w-full outline-none px-5 py-2.5 rounded-md"
                  placeholder="Confirm Password"
                  type={hideConfirmPassword ? "password" : "text"}
                  required={true}
                  value={confirmPassword}
                  onChange={(e)=> setConfirmPassword(e.target.value)}
                />
                <div
                  className="cursor-pointer"
                  onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                >
                  {hideConfirmPassword ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.99984 7.99998C9.99984 9.10455 9.10441 9.99998 7.99984 9.99998C6.89527 9.99998 5.99984 9.10455 5.99984 7.99998C5.99984 6.89541 6.89527 5.99998 7.99984 5.99998C9.10441 5.99998 9.99984 6.89541 9.99984 7.99998Z"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.00013 3.33331C5.01504 3.33331 2.48819 5.29523 1.63867 7.99996C2.48817 10.7047 5.01504 12.6666 8.00014 12.6666C10.9852 12.6666 13.5121 10.7047 14.3616 8C13.5121 5.29526 10.9852 3.33331 8.00013 3.33331Z"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 2L14 14M6.56285 6.6091C6.21442 6.96903 6 7.45948 6 8C6 9.10457 6.89543 10 8 10C8.54832 10 9.0451 9.77935 9.4064 9.42199M4.33333 4.43143C3.06713 5.26689 2.10269 6.52263 1.63867 7.99998C2.48817 10.7047 5.01504 12.6667 8.00014 12.6667C9.32607 12.6667 10.5616 12.2796 11.5999 11.6123M7.33333 3.36626C7.55265 3.34448 7.77509 3.33333 8.00013 3.33333C10.9852 3.33333 13.5121 5.29528 14.3616 8.00002C14.1744 8.59598 13.9058 9.15588 13.5688 9.66667"
                        stroke="#131A29"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div>
                <button className="btn btn-sea-green w-full mt-5" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default ResetPassword;
