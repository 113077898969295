
function DeleteConfirmation({message, setShowModal, deleteHandler}) {

    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }

     const deleteItem = () => {

        deleteHandler();
        setShowModal(false)
        

    }
    
  return (
    <div className="modal-wrapper" onClick={() => setShowModal(false)}>

    <div className='flex bg-white w-10/12 lg:w-1/3 rounded-lg box-shadow1 m-auto justify-center' style={modalStyle}>
        <div className="flex-col items-center m-5 w-full">
{
    message ?  <div className="flex-col items-center text-center mt-5">
    <p className="text-md md:text-xl font-bold">{message}</p>
</div> :
                <div className="flex-col items-center text-center mt-5">
                    <p className="text-xl md:text-3xl font-bold">Are you sure?</p>
                    <div className="text-lg text-grey-7 font-semibold">You want to delete this item</div>
                </div>
}
                <div className="flex gap-2 w-full justify-center mt-5 md:mt-10">

                    <div className="w-1/3">
                        <button  className="bg-aqua-1 text-white py-2 md:py-3 px-5 w-full rounded-lg text-sm md:text-lg"
                            onClick={deleteItem}>
                                Yes
                        </button>
                        
                        
                    </div>
                    <div className="w-1/3">
                        <button onClick={() => setShowModal(false)} className="bg-red text-white py-2 md:py-3 px-5 w-full rounded-lg text-sm md:text-lg">No</button>
                    </div>
                   

                </div>

        </div>

    </div>
    </div>
  );
}

export default DeleteConfirmation;
