import React from 'react'

function EmailVerificationModal({onClickBackToLogin, onClickClose,message}) {
  const modalStyle =  {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
 }

  return (

    <div className='modal-wrapper'>
    
      <div className='flex flex-col bg-white rounded-2xl w-1/3 p-10  gap-5' style={modalStyle} >
        <div className='flex flex-row-reverse w-full'>
          <div role="button" className='text-sea-green
           border border-sea-green px-5 py-3 rounded-md'
           onClick={onClickClose}>
            Close
          </div>
        </div>
          <div className='font-semibold text-2xl text-center'>

        {message}

          </div>

          <div className='flex justify-center w-full mt-5'>

              <button className='btn-sea-green w-1/2' onClick={onClickBackToLogin}>Back To Login</button>

          </div>
        
      </div>
    </div>
  )
}

export default EmailVerificationModal
