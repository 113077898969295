import { useState } from "react";
import { camelToTitleCase } from "../../../utils/stringNumberFormat";

function NotificationCheckbox({ list, message, onBackClick, notificationHandler, setNotificationModal }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };
  const [checkedList, setCheckedList] = useState([]);
  const SubmitHandler = (e) => {
    // e.preventDefault();
    if(checkedList.length>0){
      notificationHandler(checkedList)
    }
    setNotificationModal({list:[], show:false});
    onBackClick();
  }
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedList([...checkedList, item]);
    } 
  };
  return (
    <div className="modal-wrapper flex flex-col">

      <div className="w-10/12 lg:w-1/3" style={modalStyle}>
      <div className="flex flex-row-reverse w-full">
      <div
            role="button"
            className="text-sea-green px-5 py-3 rounded-md"
            onClick={() => {setNotificationModal({list:[], show:false}); onBackClick()}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 20L4 4M20 4L4 20"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
      </div>
      <div
        className="flex bg-white rounded-3xl box-shadow1 m-auto justify-center"
        
      >
        <div className="flex-col items-center m-5 w-full">
          <div className="flex-col items-center text-center mt-5">
            {/* <p className="text-xl md:text-3xl font-bold">Are you sure?</p> */}
            <div className="text-xl font-semibold">{message}</div>
          </div>

          <div className="flex flex-col gap-2 my-10 mx-5">
            {list?.map((item, index) => {
              return (
                <div className="flex justify-between">
                  <div className="text-lg font-bold">{camelToTitleCase(item)}</div>
                  <div>
                    <input
                      type="checkbox"
                      id={item?.id}
                      name={item}
                      className="mx-2 accent-aqua-1 accent-text-white h-4 w-4 cursor-pointer"
                      onChange={(e)=> handleCheckboxChange(e, item)}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="w-full mt-5">
            <button
              className={`${checkedList?.length < 1 ?"bg-grey-7 cursor-not-allowed":"bg-aqua-1"} text-white py-2 md:py-3 px-5 w-full rounded-lg text-sm md:text-lg`}
             onClick={SubmitHandler}
              disabled={checkedList?.length < 1}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
     
      </div>

    </div>
  );
}

export default NotificationCheckbox;
