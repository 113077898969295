import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from './general/NewHeader';

import SidePanel from './general/NewSidePanel';

import CompanyProfileMain from './subPages/companyProfile/companyProfileMain';
import Finance from './subPages/finance/Finance';
import Metrics from './subPages/metrics/Metrics';
import Sources from './subPages/sources/Sources';
import Awards from './subPages/awards/Awards';
import References from './subPages/references/References';
import ProductsMain from './subPages/products/ProductsMain';
import Analytics from './subPages/analytics/Analytics';


import CompanyProfileEdit from './subPages/companyProfile/companyProfileEdit';
import MetricsEdit from './subPages/metrics/MetricsEdit';
import FinanceEdit from './subPages/finance/FinanceEdit';
import SourcesEdit from './subPages/sources/SourcesEdit';
import AwardsAdd from './subPages/awards/AwardsAdd';
import ReferencesAdd from './subPages/references/ReferencesAdd';
import ProductsAdd from './subPages/products/ProductsAdd';
import News from './subPages/news/News';
import NewsAdd from './subPages/news/NewsAdd';
import Password from './subPages/password/Password';
import Privacy from './subPages/privacy/Privacy';
import People from './subPages/people/People';
import PeopleAdd from './subPages/people/PeopleAdd';
import ContentAdd from './subPages/content/ContentAdd';
import Content from './subPages/content/Content';
import Footer from './general/NewFooter';
import ProductsEdit from './subPages/products/ProductsEdit';
import PeopleEdit from './subPages/people/PeopleEdit';
import ReferencesEdit from './subPages/references/ReferencesEdit';
import NewsEdit from './subPages/news/NewsEdit';
import AwardsEdit from './subPages/awards/AwardsEdit';
import ContentEdit from './subPages/content/ContentEdit';
import Feedback from './subPages/feedback/Feedback';
import PrivacyEdit from './subPages/privacy/PrivacyEdit';
import { ToastContainer } from 'react-toastify';
import HeaderMobileMode from './general/HeaderMobileMode';
import Chatbot from './subPages/chatbot/Chatbot';
import profileManagement from './subPages/profile management/ProfileManagement';
import ProfileManagement from './subPages/profile management/ProfileManagement';



function CompanyProfile() {
  const activePageValues = ["dashboard", "finance", "metrics", 
  "sources", "awards", "content", "analytics", "references", 
  "products", "news", "people", "password", "privacy", "feedback","chatbot","profileManagement"];

  const [searchParams] = useSearchParams()
  let activePage = searchParams.get('activePage')
  const navigate = useNavigate()

  const [activeCategory, setActiveCategory] = useState(activePage);
  const [isEditMode, setIsEditMode] = useState(false);
 

  // Edit Product variables and functions
  const [isProductEdit, setIsProductEdit] = useState(false)
  const [isProductEditId, setIsProductEditId] = useState(null)
  const [productActivePage, setProductActivePage] = useState(1)


  function handleIsProductEdit(editProduct, activePage){
    const {isEdit, productId} = editProduct;
    
    setIsProductEdit(isEdit)
    setIsProductEditId(productId)
    setProductActivePage(activePage)
  }


  // Edit Reference variables and functions
  const [isReferenceEdit, setIsReferenceEdit] = useState(false)
  const [isReferenceEditId, setIsReferenceEditId] = useState(null)

  function handleIsReferenceEdit(editReference){
    const {isEdit, referenceId} = editReference;
    
    setIsReferenceEdit(isEdit)
    setIsReferenceEditId(referenceId)

  }
  // Edit Award variables and functions
  const [isAwardEdit, setIsAwardEdit] = useState(false)
  const [isAwardEditId, setIsAwardEditId] = useState(null)

  function handleIsAwardEdit(editAward){
    const {isEdit, awardId} = editAward;
    
    setIsAwardEdit(isEdit)
    setIsAwardEditId(awardId)

  }
  // Edit Content variables and functions
  const [isContentEdit, setIsContentEdit] = useState(false)
  const [isContentEditId, setIsContentEditId] = useState(null)

  function handleIsContentEdit(editContent){
    const {isEdit, contentId} = editContent;
    console.log("cp id: ", contentId)
    setIsContentEdit(isEdit)
    setIsContentEditId(contentId)

  }
  const toggleEditMode = () => {
    
    setIsEditMode(!isEditMode)
   
  }

  // Edit News variables and functions
  const [isNewsEdit, setIsNewsEdit] = useState(false)
  const [isNewsEditId, setIsNewsEditId] = useState(null)
  const [newsActivePage, setNewsActivePage] = useState(1)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)

  function handleIsNewsEdit(editNews, activePage){
    const {isEdit, newsId} = editNews;
    const openNotiModal = editNews?.openNotificationModal;
    console.log("ISEDIT : ", isEdit ," ", "newsId : ", newsId )
    
    setIsNewsEdit(isEdit)
    setIsNewsEditId(newsId)
    setNewsActivePage(activePage)
    setOpenNotificationModal(openNotiModal)

  }

  // Edit People variables and functions
  const [isPeopleEdit, setIsPeopleEdit] = useState(false)
  const [isPeopleEditId, setIsPeopleEditId] = useState(null)

  function handleIsPeopleEdit(editPeople){
    const {isEdit, peopleId} = editPeople;
    
    setIsPeopleEdit(isEdit)
    setIsPeopleEditId(peopleId)

  } 


  useEffect(() => {
    if(!activePage){
     navigate('/company?activePage=dashboard')
    }
    if(activePageValues.includes(activePage)){
      setActiveCategory(activePage);
      setIsEditMode(false)
      setIsProductEdit(false)
      setIsPeopleEdit(false)
      setIsAwardEdit(false)
      setIsContentEdit(false)
      setIsNewsEdit(false)
      setIsReferenceEdit(false)
    }else{
      navigate('/page-not-found')
    }

  
  
  }, [activePage])

  console.log("ACTIVE CATEGORY: ", activeCategory)

  return (
    <div className='bg-grey-1 flex flex-col gap-5 relative min-h-screen'>
      {/* Header */}
      <Header/>
      <HeaderMobileMode fetchActiveCategory={setActiveCategory} currentCategory = {activeCategory} activePage={activePage}/>

      {/* Side Panel and other content */}
      <div className='flex gap-5 lg:w-10/12 lg:m-auto mt-32 lg:mt-48'>

        <div className="hidden lg:block w-1/6">
        <SidePanel fetchActiveCategory={setActiveCategory} currentCategory = {activeCategory} activePage={activePage}/>
        </div>

        <div className='m-auto lg:m-0 w-11/12 lg:w-5/6'>

        {
          activeCategory === "dashboard"?
          isEditMode?<CompanyProfileEdit onBackClick={()=>{setIsEditMode(false)}}/>
          :<CompanyProfileMain onEditClick={()=>{ setIsEditMode(true)}} addContent={()=>setActiveCategory("Content")}/>

          :activeCategory === "finance"?isEditMode?
          <FinanceEdit onBackClick={()=>{setIsEditMode(false)}}/>
          :<Finance onEditClick={()=>{ setIsEditMode(true)}}/>

          :activeCategory === "metrics"?isEditMode?
          <MetricsEdit onBackClick={()=>{setIsEditMode(false)}}/>
          :<Metrics onEditClick={()=>{ setIsEditMode(true)}}/>

          :activeCategory === "sources"?isEditMode?
          <SourcesEdit onBackClick={()=>{setIsEditMode(false)}}/>
          :<Sources onEditClick={()=>{ setIsEditMode(true)}}/>

          :activeCategory === "awards"?isEditMode?
          <AwardsAdd onBackClick={()=>{setIsEditMode(false)}}/>
          :isAwardEdit?<AwardsEdit onBackClick={()=>{setIsAwardEdit(false)}} awardId={isAwardEditId}/>
          :<Awards onEditClick={()=>{ setIsEditMode(true) }} fetchIsAwardEdit = {handleIsAwardEdit} />

          :activeCategory === "content"?isEditMode?
          <ContentAdd onBackClick={toggleEditMode}/>
          :isContentEdit?<ContentEdit onBackClick={()=>{setIsContentEdit(false)}} contentId={isContentEditId}/>
          :<Content onEditClick={toggleEditMode} fetchIsContentEdit = {handleIsContentEdit}/>

          :activeCategory === "analytics"?<Analytics/>

          :activeCategory === "references"?isEditMode?
          <ReferencesAdd onBackClick={()=>{setIsEditMode(false)}}/>
          :isReferenceEdit?<ReferencesEdit onBackClick={()=>{setIsReferenceEdit(false)}} referenceId={isReferenceEditId}/>
          :<References onEditClick={()=>{ setIsEditMode(true)}} fetchIsReferenceEdit = {handleIsReferenceEdit}/>

          :activeCategory === "products"?isEditMode?
          <ProductsAdd onBackClick={()=>{setIsEditMode(false)}}/>
          :isProductEdit?<ProductsEdit onBackClick={()=>{setIsProductEdit(false)}} productId={isProductEditId}/>
          :<ProductsMain onEditClick={()=>{ setIsEditMode(true)}} fetchIsProductEdit = {handleIsProductEdit} activePage = {productActivePage}/>

          :activeCategory === "news"?isEditMode?
          <NewsAdd onBackClick={()=>{setIsEditMode(false)}} fetchIsNewsEdit={handleIsNewsEdit}/>
          :isNewsEdit?<NewsEdit onBackClick={()=>{setIsNewsEdit(false)}} newsId={isNewsEditId} openNotificationModal={openNotificationModal} setOpenNotificationModal={setOpenNotificationModal} />
          :<News onEditClick={()=>{ setIsEditMode(true)}} fetchIsNewsEdit={handleIsNewsEdit} activePage = {newsActivePage}/>

          :activeCategory === "people"?isEditMode?
          <PeopleAdd onBackClick={()=>{setIsEditMode(false)}}/>
          :isPeopleEdit?<PeopleEdit onBackClick={()=>{setIsPeopleEdit(false)}} peopleId={isPeopleEditId}/>
          :<People onEditClick={()=>{ setIsEditMode(true)}} fetchIsPeopleEdit = {handleIsPeopleEdit}/>

       

          :activeCategory === "password"?<Password/>
          :activeCategory === "chatbot"?<Chatbot/>
          :activeCategory === "profileManagement"?<ProfileManagement/>
        
          :activeCategory === "privacy"?isEditMode?
          <PrivacyEdit onBackClick={()=>{setIsEditMode(false)}}/>
          :<Privacy onEditClick={()=>{ setIsEditMode(true)}}/>

          :activeCategory === "feedback"?<Feedback/>:""
          
        }

        </div>

      </div>

      <ToastContainer/>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default CompanyProfile;
