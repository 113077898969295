import React, { useEffect, useState } from "react";
import useFirebaseAuth from "../../../auth/useFirebaseAuth";
import {
  removeOnBoardCookie,
  setCompanyCookie,
  setOnBoardCookie,
} from "../../../auth/cookies";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import { getMethodTokenless } from "../../../utils/apiMethod";

function GoogleSignUpModal({ onClose }) {
  const url = process.env.REACT_APP_BASE_URL;
  // const url = "http://44.199.133.157:8090/v1/"

  const navigate = useNavigate();
  const { signInWithGoogle, signOut } = useFirebaseAuth();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };

  const [companyName, setCompanyName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [companyTypeId, setCompanyTypeId] = useState("");
  const [companyTypeList, setCompanyTypeList] = useState(null)

  function handleRegisterApiCall(userCredential, details) {
    console.log(userCredential?.user.displayName);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + userCredential.user.multiFactor.user.accessToken
    );

    var raw = JSON.stringify({
      name: details.companyName,
      companyTypeId: details.companyTypeId,
      ownerName: details.ownerName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url + "auth/register", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          removeOnBoardCookie();
          setOnBoardCookie(userCredential.user.multiFactor.user.accessToken);
          setCompanyCookie(result);

          onClose();
          toastSuccess(result.message);
          console.log("Registration successful:", userCredential.user);
          navigate("/company?activePage=dashboard");
        } else {
          console.log("Error: ", result);
          onClose();
          toastError(result.message);
        }
      })
      .catch((error) => {
        onClose();
        console.log("error", error);
        toastError(error.message);
      });
  }

  const signUpWithGoogleHandler = async (e) => {
    e.preventDefault();

    if (companyTypeId && companyName) {
      signInWithGoogle()
        .then((authUser) => {
          console.log("AUTH USER: ", authUser);

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            email: authUser.user.email,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
          };

          try {
            fetch(url + "auth/checkUserExistence", requestOptions)
              .then((response) => {
                return response.json();
              })
              .then((response) => {
                console.log("Response from existence api: ", response);

                if (response.status) {
                  if (response.exists) {
                    //  LoginQuery(authUser.user.multiFactor.user.accessToken).then((response) => {
                    //    return response.json()
                    //  })
                    //  .then((response) => {
                    //    console.log(response)
                    //    if(response.status){
                    //      setOnBoardCookie(authUser.user.multiFactor.user.accessToken)
                    //      setCompanyCookie(response)
                    //      navigate('/company')
                    //    }
                    //    else{
                    //      toastError(response.message);
                    //      signOut();
                    //    }

                    //  })

                    signOut();

                    toastWarn("User already exists. Kindly Sign In.");

                    onClose();
                  } else {
                    let details = {
                      companyTypeId: companyTypeId,

                      companyName: companyName,
                      ownerName: ownerName,
                    };

                    console.log("DETAILS: ", details);

                    handleRegisterApiCall(authUser, details);
                  }
                } else {
                  console.log("RESPONSE.STATUS: ", response.status);
                }
              });
          } catch (error) {
            console.log("Error: ", error);
          }
        })
        .catch((error) => {
          // setErrorBox(true)
          // setErrorMsg(error.message);
          // setLoading(false);
          console.log("Error: ", error);
        });
    } else {
      toastError("Fill all the fields");
    }
  };

  useEffect(() => {
    // setLoading(true);
    getMethodTokenless(`auth/getCompanyTypes`)
      .then((res) => {
        // setLoading(false);

        if (res?.status) {
          setCompanyTypeList(res?.types);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="modal-wrapper">
      <div
        className="flex flex-col bg-white rounded-2xl w-5/6 lg:w-1/3 p-10  gap-5"
        style={modalStyle}
      >
        <div className="flex flex-row-reverse w-full">
          <div
            role="button"
            className="text-sea-green
           border border-sea-green px-5 py-3 rounded-md"
            onClick={() => onClose(false)}
          >
            Close
          </div>
        </div>
        <form onSubmit={signUpWithGoogleHandler}>
          {/* Company Name */}

          <div className="border-[1.5px] border-grey-14 w-full p-3 lg:p-5 pl-10 rounded-lg lg:rounded-2xl ">
            <input
              className="outline-none placeholder-grey-7 text-xl w-full h-full"
              placeholder="Company Name"
              name="companyName"
              required={true}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="border-[1.5px] border-grey-14 w-full p-3 lg:p-5 pl-10 rounded-lg lg:rounded-2xl mt-5">
            <input
              className="outline-none placeholder-grey-7 text-xl w-full h-full"
              placeholder="Owner Name"
              name="ownerName"
              required={true}
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </div>

          {/* Company Type  */}

          <div className="mt-5">
            <div className="border-[1.5px] border-grey-14 w-full p-3 lg:p-5 pl-10 rounded-lg lg:rounded-2xl mt-5">
              <select
                className="login-form-input-field"
                placeholder="Company Name"
                name="companyName"
                required={true}
                value={companyTypeId}
                onChange={(e) => setCompanyTypeId(e.target.value)}
              >
                <option value="">Select an option</option>
                {companyTypeList?.map((item, index) => {
                  return (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* 
                                {
                                 isCompanyTypePresent === false && <p className="text-red pl-10 m-0">Please select a company type!</p>
                               } */}
          </div>
          <button className="flex justify-center w-full mt-5" type="submit">
            <div
              role="button"
              className="border-[1.5px] border-grey-14 w-full p-3 lg:p-5 rounded-lg lg:rounded-2xl flex justify-center gap-3 items-center"
            >
              <div>
                <img src="/assets/google-logo.svg" />
              </div>
              <div className="text-xl text-grey-10 font-base cursor-pointer">
                Sign up with Google
              </div>
            </div>
          </button>
        </form>
      </div>
    </div>
  );
}

export default GoogleSignUpModal;
