import React, { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useFirebaseAuth from "../../auth/useFirebaseAuth";
import { LoginQuery } from "../../utils/auth";
import { setCompanyCookie, setOnBoardCookie } from "../../auth/cookies";
import ForgotPassword from "../general/modals/ForgotPassword";
import SignInWithGoogle from "../general/SignInWithGoogle";
import { toastError, toastSuccess } from "../../utils/Toasters";
import { ToastContainer } from "react-toastify";
import { StateContext } from "../../Context/StateContext";
import { signOut } from "firebase/auth";
import SignInWithLinkedIn from "../general/SignInWithLinkedIn";
import firebase from "../../auth/firebaseConfig";
import { postMethodTokenless } from "../../utils/apiMethod";
import GoogleSignUpModal from "../general/modals/GoogleSignUpModal";

function LoginForm() {
  const navigate = useNavigate();
  const { signInWithEmailAndPassword, signInWithGoogle } = useFirebaseAuth();

  const { loading, setLoading } = useContext(StateContext);

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showGoogleSignUpModal, setShowGoogleSignUpModal] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((authState) => {
        setLoading(false);
        console.log("authState: ", authState);
        LoginHandler(authState)
        // if (authState.user.emailVerified) {
        //   LoginQuery(authState.user.multiFactor.user.accessToken)
        //     .then((response) => {
        //       // setLoading(false)
        //       return response.json();
        //     })
        //     .then((response) => {
        //       setLoading(false);
        //       const resp = response.company;
        //       if (response.status) {
        //         console.log(response);

        //         setOnBoardCookie(authState.user.multiFactor.user.accessToken);
        //         setCompanyCookie(response);

        //         toastSuccess("Logged In successfully!");

        //         navigate("/company?activePage=dashboard");

        //         // if(firebase.auth().currentUser.emailVerified == true){

        //         //   setOnBoardCookie(authState.user.multiFactor.user.accessToken);
        //         //   setCompanyCookie(response);

        //         //   toastSuccess("Logged In successfully!");

        //         //   navigate("/company?activePage=dashboard");
        //         // }else{
        //         //   toastError("Please verify your email!")
        //         // }
        //       } else {
        //         toastError(response.message);
        //         setLoading(false);
        //         // signOut()
        //       }
        //     })
        //     .catch((error) => {
        //       console.log("ERROR: ", error);

        //       setLoading(false);
        //       toastError(error.message);
        //     });
        // } else {
        //   authState.user.sendEmailVerification();
        //   toastError(
        //     "Email not verified. Verification link sent to your email"
        //   );
        // }
      })
      .catch((error) => {
        LoginHandler()
        setLoading(false);
        console.log("ERROR: ", error);
        // toastError(error.message);
      });
  };

  const IsRegistered = (bool) => {

    if(!bool){
      setShowGoogleSignUpModal(true)
    }

  }
  const LoginHandler = (authState) => {
    setLoading(true);
    var raw = JSON.stringify({
      email: credentials?.email,
      password: credentials?.password, //alphanumneric
    });
    postMethodTokenless(`auth/login`, raw, authState?.user?.multiFactor?.user?.accessToken)
      .then((res) => {
        setLoading(false);
        if (res.status == true) {
          if(authState?.user?.multiFactor?.user?.accessToken){
            setOnBoardCookie(authState?.user?.multiFactor?.user?.accessToken);
          }else{
            setOnBoardCookie(res?.token);
          }
          setCompanyCookie(res?.company);
  

          toastSuccess(res?.message);

          navigate("/company?activePage=dashboard");
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  return (
    <div className="xs:flex-col lg:flex m-auto">
      {/* form start */}

      <div className="flex flex-col w-full lg:w-2/5 lg:ms-36 gap-8 px-10">
        <div className="text-aqua-1 text-5xl text-center font-extrabold md:leading-[85px] lg:text-[85px] lg:text-start w-full">
          Your future is futr
        </div>

        {/* input fields */}

        <form className="flex flex-col gap-5" onSubmit={SubmitHandler}>
          <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z"
                fill="#1CBAAB"
              />
            </svg>
            <input
              className="outline-none placeholder-grey-7 text-xl w-full h-full"
              placeholder="Enter your work email"
              name="email"
              value={credentials.email}
              onChange={handleInputChange}
              required={true}
            />
          </div>
          <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V10C4 9.45 4.196 8.97933 4.588 8.588C4.98 8.19667 5.45067 8.00067 6 8H7V6C7 4.61667 7.48767 3.43767 8.463 2.463C9.43833 1.48833 10.6173 1.00067 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.8043 13.9793 13.413 13.588C13.0217 13.1967 12.5507 13.0007 12 13C11.45 13 10.9793 13.196 10.588 13.588C10.1967 13.98 10.0007 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                fill="#1CBAAB"
              />
            </svg>
            <div className="border-r-[1.5px] w-3/4 border-r-grey-14">
              <input
                className="outline-none placeholder-grey-7 text-xl w-full"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                required={true}
              />
            </div>
            <div
              className="m-auto font-400 text-xl text-sea-green cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </div>
          </div>

          <div
            role="button"
            className="font-normal 
            text-base text-grey-10 cursor-pointer"
            onClick={() => {
              setShowForgotPasswordModal(true);
            }}
          >
            Forgot Password?
          </div>

          <button
            className={`w-full p-5 rounded-2xl 
            flex justify-center text-white 
            font-normal text-xl ${
              credentials?.email.length > 0 &&
              credentials?.email.includes("@") &&
              credentials?.email.includes(".") &&
              credentials?.password?.length > 5
                ? "bg-sea-green cursor-pointer"
                : "bg-grey-7 cursor-not-allowed"
            }`}
            disabled={
              !(
                credentials?.email.length > 0 &&
                credentials?.email.includes("@") &&
                credentials?.email.includes(".") &&
                credentials?.password?.length > 5
              )
            }
            type="submit"
          >
            Sign In
          </button>

          <div className="text-xl text-grey-13 font-base m-auto">Or</div>

          {/* <div className="border-[1.5px] border-black-1 w-full p-5 rounded-full flex justify-center gap-3 items-center">
              <div>
                <img src="assets/google-logo.svg" />
              </div>
              <div className="text-xl text-grey-13 font-base cursor-pointer">
                Sign In with Google
              </div> 
            </div> */}
          <SignInWithGoogle isRegistered={IsRegistered}/>
          <div className="border-[1.5px] border-grey-14 w-full p-5 rounded-2xl flex justify-center gap-3 items-center">
            <div>
              <img src="assets/linkedin-logo.svg" />
            </div>
            <SignInWithLinkedIn />
          </div>

          <div className="text-center text-grey-10 text-xl">
            Don't have an account?{" "}
            <span
              className="text-sea-green font-bold cursor-pointer"
              onClick={() => navigate("/register")}
            >
              Join Now
            </span>
          </div>
        </form>
      </div>

      {/* form end */}

      <div>
        <img src="/assets/form-image.png" />
      </div>

      {showForgotPasswordModal && (
        <ForgotPassword
          onClickClose={() => setShowForgotPasswordModal(false)}
        />
      )}
      {/* <ToastContainer/> */}
      {
          showGoogleSignUpModal && <GoogleSignUpModal onClose={setShowGoogleSignUpModal}/>
        }
    </div>
  );
}

export default LoginForm;
