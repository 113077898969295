import { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../general/NewInputField";
import {
  deleteMethod,
  getMethod,
  postFormMethod,
  postMethod,
} from "../../../utils/apiMethod";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import { StateContext } from "../../../Context/StateContext";
import { MdEdit } from "react-icons/md";
import EditTestimonial from "../../general/modals/EditTestimonial";
import ImageUpload from "../../general/ImageUpload";
import ResourceUpload from "../../general/ResourceUpload";
import NotificationConfirmation from "../../general/modals/NotificationConfirmation";
import { SendProductNotification } from "../../../utils/notificationApis";
function ProductsAdd({ onBackClick }) {
  const [notificationModal, setNotificationModal] = useState(false);
  const [stepper, setStepper] = useState(0);
  const { setLoading, profileStatus } = useContext(StateContext);
  const [benefits, setBenefits] = useState([""]);
  const [testimonials, setTestimonials] = useState({
    customerName: "",
    title: "",
    companyName: "",
    description: "",
    image: "",
  });

  const [testimonialDetails, setTestimonialDetails] = useState([]);
  const [openUpdateTestiModal, setOpenUpdateTestiModal] = useState(false);
  const [testimonialId, setTestimonialId] = useState("");

  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [productDescription, setProductDescription] = useState("");
  // const [productTypeDetails, setProductTypeDetails] = useState([]);
  const [productType, setProductType] = useState("");

  const [productImage, setProductImage] = useState(null);
  const fileInputRef = useRef(null);

  const [testiImage, setTestiImage] = useState("");
  const fileTestiInputRef = useRef(null);
  const [refresher, setRefresher] = useState(false);

  const [productVideo, setProductVideo] = useState("");
  // const videoFileInputRef = useRef(null);

  // video functions
  // const handleVideoClick = () => {
  //   if (videoFileInputRef.current) {
  //     videoFileInputRef.current.click();
  //   }
  // };

  // const handleVideoFileChange = (e) => {
  //   const selectedVideoFile = e.target.files[0];
  //   setProductVideo(selectedVideoFile);
  //   // Do something with the selected file
  // };

  // resources functions
  const [resourcesList, setResourcesList] = useState([]);
  const resourceFileInputRef = useRef(null);

  // testimonials functions
  // image
  const handleTestiClick = () => {
    fileTestiInputRef.current.click(); // Trigger the file input dialog
  };

  const handleTestiFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // const imageURL = URL.createObjectURL(selectedFile)
    setTestiImage(selectedFile);
    // Do something with the selected file
  };

  // other functions
  const handleBenefitChange = (index, value) => {
    const newBenefits = [...benefits];
    newBenefits[index] = value;
    setBenefits(newBenefits);
  };

  const handleAddBenefits = () => {
    setBenefits([...benefits, ""]);
  };

  const handleTestimonialChange = (e) => {
    // const { name, value } = event.target;
    // const list = [...testimonials];
    // list[index][name] = value;
    // setTestimonials(list);
    setTestimonials({
      ...testimonials,
      [e.currentTarget.name]: e.target.value,
    });
  };

  const handleUpdateTestimonial = (id) => {
    setTestimonialId(id);
    setOpenUpdateTestiModal(true);
  };

  // const fetchSelectedOption = (prodType) => {
  //   setProductTypeDetails(prodType);
  // };

  const cancelBenefitHandler = (index) => {
    console.log("IDS BENEFITS : ", index);
    let arr = [...benefits];
    arr.splice(index, 1);
    setBenefits(arr);

    // setBenefits(benefits?.filter((item) => item?.id != id))
  };
  const deleteTestimonial = (id, index) => {
    // let arr = [...testimonialDetails];
    // arr.splice(index,1);
    // setTestimonialDetails(arr);

    deleteMethod(`product/deleteTestimonial/${id}`)
      .then((res) => {
        console.log("RESPONSE: ", res);
        if (res.status === true) {
          //   setOpen(false);
          setTestimonialDetails(
            testimonialDetails.filter((item) => item.id !== id)
          );

          toastWarn(res.message);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });
  };
  function validateUrl(url) {
    try {
      let validate = new URL(url);

      return true;
    } catch (error) {
      throw new Error(`Video URL not valid!`);
    }
  }
  // SAVE BUTTON HANDLERS
  const saveProductDetails = (e) => {
    e.preventDefault();
    try {
      var formdata = new FormData();
      // if (productImage !== null) {
      //   formdata.append("productImage", productImage);
      // }
      if (productName.length > 0) {
        formdata.append("name", productName);
      }

      if (productDescription.length > 0) {
        formdata.append("description", productDescription);
      }
      if (productType.length > 0) {
        formdata.append("productType", productType);
      }
      var raw = {};

      if (productName.length > 0) {
        raw = { ...raw, name: productName };
      }
      if (productDescription.length > 0) {
        raw = { ...raw, description: productDescription };
      }
      if (productType.length > 0) {
        raw = { ...raw, productType: productType };
      }
      if (productVideo.length > 0) {
        validateUrl(productVideo);
        raw = { ...raw, productVideo: productVideo };
      }

      raw = JSON.stringify(raw);

      postMethod(`product/addProduct`, raw)
        .then((res) => {
          if (res.status === true) {
            console.log("PRODUCT RES : ", res);
            setProductId(res?.data?.id);
            setStepper(1);
            toastSuccess("Product details added");
            if(profileStatus === "2") {
              setNotificationModal(true);
            }
          } else {
            toastError(res.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    } catch (error) {
      toastError(error.message);
    }
  };
  const saveBenefits = (e) => {
    e.preventDefault();
    let raw;
    raw = JSON.stringify({
      productId: productId,
      benefits: benefits,
    });
    postMethod(`product/addBenefits`, raw)
      .then((res) => {
        if (res.status === true) {
          console.log("BENEFITS RES : ", res);
          toastSuccess(res?.message);
          setStepper(2);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };
  const saveTestimonials = () => {
    var formdata = new FormData();
    console.log("COMPANY NAME: ", testimonials?.companyName);
    formdata.append("productId", productId);
    formdata.append("customerName", testimonials?.customerName);
    formdata.append("companyName", testimonials?.companyName);
    formdata.append("description", testimonials?.description);
    formdata.append("title", testimonials?.title);
    if (testiImage) {
      formdata.append("image", testiImage);
    }

    postFormMethod(`product/addTestimonial`, formdata)
      .then((res) => {
        if (res.status === true) {
          console.log("TESTIMONIALS RES : ", res);
          toastSuccess(res?.message);
          setTestimonials({
            customerName: "",
            title: "",
            description: "",
            companyName: "",
            image: "",
          });
          setTestiImage(null);
          setTestimonialDetails([...testimonialDetails, res?.data]);

          // setStepper(3);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };
  const saveNextTestimonials = () => {
    if (
      testimonials?.title.length < 1 &&
      testimonials?.customerName.length < 1 &&
      testimonials?.description.length < 1
    ) {
      setStepper(3);
    } else {
      var formdata = new FormData();

      formdata.append("productId", productId);
      formdata.append("customerName", testimonials?.customerName);
      formdata.append("companyName", testimonials?.companyName);
      formdata.append("description", testimonials?.description);
      formdata.append("title", testimonials?.title);
      if (testiImage) {
        formdata.append("image", testiImage);
      }

      postFormMethod(`product/addTestimonial`, formdata)
        .then((res) => {
          if (res.status === true) {
            console.log("TESTIMONIALS RES : ", res);
            toastSuccess(res?.message);
            // setTestimonials({ customerName: "",
            // title: "",
            // description: "",
            // image:""})
            // setTestiImage(null)
            // setTestimonialDetails([...testimonialDetails, res?.data])

            setStepper(3);
          } else {
            toastError(res.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    }
  };
  const saveResources = () => {
    var mediaFormData = new FormData();
    mediaFormData.append("productId", productId);

    if (resourcesList.length > 0) {
      resourcesList.forEach((resource) => {
        mediaFormData.append("productResources", resource?.main);
      });
    }

    postFormMethod("product/addUpdateProductMedia", mediaFormData)
      .then((response) => {
        setLoading(false);
        console.log("RESPONSE : ", response.data);

        if (response.status === true) {
          toastSuccess(response.message);
          onBackClick();
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    if (productId) {
      setLoading(true);
      getMethod(`product/getAllTestimonials/${productId}`)
        .then((res) => {
          setLoading(false);
          if (res?.status) {
            setTestimonialDetails([...res?.data]);
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    }
  }, [refresher]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div">
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px] capitalize">
                Add product info
              </div>
              <div className="text-grey-2 capitalize">
                Add new product details
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* Product info */}
        {stepper === 0 && (
          <form onSubmit={saveProductDetails}>
            <div className="form-outline-wrapper-div p-8 gap-8">
              {/* Product details */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <InputField
                  label="Product Name"
                  placeholder="Product full name"
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                  optional={false}
                  width={"w-full"}
                />
                <InputField
                  label="Product Type"
                  placeholder="Product Type"
                  width={"w-full"}
                  maxLength={30}
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                />

                <InputField
                  label="Product Video"
                  placeholder="Product Video Link"
                  optional={true}
                  width={"w-full"}
                  value={productVideo}
                  onChange={(e) => setProductVideo(e.target.value)}
                  // onChange={(e) => setProductType(e.target.value)}
                />

                {/* <SelectProductType
                  title={"product"}
                  fetchSelectedOption={fetchSelectedOption}
                  isRequired={true}
                  width={"w-full"}
                />  */}

                {/* <InputField
                  label="Product Description"
                  placeholder="Product Description"
                  onChange={(e) => setProductDescription(e.target.value)}
                  maxLength={360}
                  optional={false}
                  width={"w-full"}
                /> */}
              </div>
              <div className={`flex flex-col gap-4 w-full`}>
                <div>
                  Description<span className="text-orange-1">*</span> &nbsp;
                  {/* <span className="text-grey-10 text-sm">{` ( ${description?.length} / 300 )`}</span> */}
                  <span className="text-grey-8"> (max 360 characters)</span>
                </div>

                <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-2 h-20">
                  <textarea
                    className="focus:border-transparent outline-none w-full"
                    placeholder="Enter description"
                    style={{ resize: "none" }}
                    value={productDescription}
                    required={true}
                    onChange={(e) => setProductDescription(e.target.value)}
                    maxLength={"360"}
                  ></textarea>
                </div>
              </div>
            </div>

            {stepper === 0 && (
              <div className="flex flex-wrap items-center justify-end mt-5">
                <div className="flex justify-end lg:w-3/12">
                  <button
                    className="btn-sea-green text-xl w-full"
                    type="submit"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            )}
          </form>
        )}

        {/* Benefits */}
        {stepper === 1 && (
          <form onSubmit={saveBenefits}>
            <div className="form-outline-wrapper-div p-8 gap-8">
              <div>
                Benefits: &nbsp;{" "}
                <span className="text-grey-7">
                  ( Maximum 6 Benefits Allowed )
                </span>
              </div>
              <div className="flex flex-col gap-3">
                {benefits.map((value, index) => {
                  return (
                    // <div key={index}>
                    //   <InputField
                    //     label={`Benefit ${index + 1}`}
                    //     placeholder="Enter Benefit"
                    //     width="full"
                    //     onChange={(e) => handleBenefitChange(index, e.target.value)}
                    //     maxLength = {72}
                    //     cancelButton={true}
                    //     cancelHandler={}
                    //   />
                    // </div>

                    <>
                      <div className="font-light">
                        {`Benefit ${index + 1}`}{" "}
                        <span className="text-orange-1">*</span>{" "}
                        <span className="text-grey-12">
                          &nbsp;( max 100 characters )
                        </span>{" "}
                      </div>
                      <div
                        className={`flex items-center rounded-lg border border-grey-7 h-12 justify-between px-5`}
                      >
                        <input
                          className="focus:border-transparent outline-none w-full h-full"
                          placeholder="Enter Benefit"
                          value={value}
                          onChange={(e) =>
                            handleBenefitChange(index, e.target.value)
                          }
                          maxLength={100}
                          required={true}
                        />
                        {benefits?.length > 1 && (
                          <div
                            role="button"
                            onClick={() => cancelBenefitHandler(index)}
                          >
                            <img src="/assets/cancel.svg" alt="cancel" />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}

                {benefits.length < 6 && (
                  <div
                    role="button"
                    onClick={handleAddBenefits}
                    className="text-sea-green font-medium cursor-pointer"
                  >
                    Add more
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-wrap lg:items-center justify-between mt-5 lg:mt-0">
              <div className="flex justify-end lg:w-3/12">
                {/* <button
                className="btn-sea-green text-xl w-full"
                onClick={() => setStepper(0)}
              >
                Back
              </button> */}
              </div>
              <div className="flex w-full lg:w-3/12">
                <button className="btn-sea-green text-xl w-full" type="submit">
                  Save & Continue
                </button>
              </div>
            </div>
          </form>
        )}

        {/* Testimonials */}
        {stepper === 2 && (
          <>
            <div className="form-outline-wrapper-div p-8 gap-8">
              <div>Customer Testimonials:</div>

              <div className={`flex flex-col gap-4 w-full`}>
                <div className="font-light">
                  Testimonial
                  {/* <span className="text-orange-1">*</span> */}
                </div>
                <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-3">
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 lg:w-1/3">
                    <input
                      placeholder="Client Name"
                      className="focus:border-transparent outline-none bg-[#f2f8f8] w-full"
                      value={testimonials.customerName}
                      name="customerName"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>

                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 w-full lg:w-1/3">
                    <input
                      placeholder="Client Position or Title"
                      className="focus:border-transparent 
                                  outline-none bg-[#f2f8f8] w-full"
                      value={testimonials.title}
                      name="title"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>
                  <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 w-full lg:w-1/3">
                    <input
                      placeholder="Client Company Name"
                      className="focus:border-transparent 
                                  outline-none bg-[#f2f8f8] w-full"
                      value={testimonials.companyName}
                      name="companyName"
                      onChange={(e) => handleTestimonialChange(e)}
                    />
                  </div>
                  <input
                    className="focus:border-transparent outline-none w-full h-full"
                    placeholder="Client Quote (max 270 characters)"
                    value={testimonials.description}
                    name="description"
                    onChange={(e) => handleTestimonialChange(e)}
                    maxLength={270}
                  />

                  {/* Testimonial Image */}

                  <div className="flex flex-col gap-2">
                    <ImageUpload
                      thumbnail={testiImage}
                      setThumbnail={setTestiImage}
                      required={false}
                    />
                    <div className="text-grey-7 text-sm">
                      ( Client Photo or Company Logo )
                    </div>
                  </div>
                </div>
              </div>

              {/* UPLOADED TESTIMONIALS */}
              {testimonialDetails?.map((item, index) => {
                return (
                  <div key={index} className={`flex flex-col gap-4 w-full`}>
                    <div className="font-light">
                      Testimonial {index + 1}
                      {/* <span className="text-orange-1">*</span> */}
                    </div>
                    <div className="flex flex-col rounded-lg border border-grey-7 p-3 gap-3">
                      <div className="flex gap-2 flex-row-reverse items-center">
                        <div
                          className="flex cursor-pointer"
                          onClick={() => deleteTestimonial(item?.id, index)}
                        >
                          <img src="/assets/cancel.svg" alt="cancel" />
                        </div>
                        <div
                          className="flex cursor-pointer"
                          onClick={() => handleUpdateTestimonial(item?.id)}
                        >
                          {/* <img src="/assets/cancel.svg" /> */}
                          <MdEdit className="text-sea-green text-xl" />
                        </div>
                      </div>

                      <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 lg:w-1/6">
                        <input
                          placeholder="Type Customer Name"
                          className="focus:border-transparent outline-none bg-[#f2f8f8] m-auto"
                          value={item.customerName}
                          name="customerName"
                          readOnly={true}
                        />
                      </div>

                      <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 lg:w-1/6">
                        <input
                          placeholder="Type Title"
                          className="focus:border-transparent 
                                      outline-none bg-[#f2f8f8] m-auto"
                          value={item.title}
                          name="title"
                          readOnly={true}
                        />
                      </div>
                      <div className="rounded-md text-xs text-center text-grey-10 bg-[#f2f8f8] p-1 px-5 lg:w-1/6">
                        <input
                          placeholder="Company Name"
                          className="focus:border-transparent 
                                      outline-none bg-[#f2f8f8] m-auto"
                          value={item.companyName}
                          name="companyName"
                          readOnly={true}
                        />
                      </div>
                      <input
                        className="focus:border-transparent outline-none w-full h-full"
                        placeholder="Client Quote"
                        value={item.description}
                        name="description"
                      />

                      {/* Testimonial Image */}

                      <div className="flex gap-2 items-center">
                        <div className="flex items-center rounded-2xl bg-light-sea-green-1 h-14 w-14 cursor-pointer">
                          <div className="flex items-center m-auto h-full w-full rounded-2xl">
                            <img
                              src={
                                item?.imageUrl && item?.imageUrl.length > 0
                                  ? item?.imageUrl
                                  : "/assets/documentupload.svg"
                              }
                              alt="upload"
                              className={`${
                                item?.imageUrl && item?.imageUrl.length > 0
                                  ? "flex items-center m-auto h-full w-full rounded-2xl cursor-pointer"
                                  : "m-auto cursor-pointer"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      role="button"
                      onClick={handleAddTestimonials}
                      className="text-sea-green font-medium"
                    >
                      Add more
                    </div> */}
                  </div>
                );
              })}
            </div>

            <div className="xs:flex-col lg:flex lg:flex-wrap lg:items-center lg:justify-between">
              <div className="flex justify-end lg:w-2/12">
                <button
                  className="btn-sea-green text-xl w-full"
                  onClick={saveTestimonials}
                >
                  Save & Add More
                </button>
              </div>
              <div className="flex justify-end mt-5 lg:mt-0 lg:w-2/12">
                <button
                  className="btn-sea-green text-xl w-full"
                  onClick={saveNextTestimonials}
                >
                  Save & Continue
                </button>
              </div>
              {/* <div className="grid grid-cols-2">
            

              
            </div> */}
            </div>
          </>
        )}

        {/* Product Video */}
        {stepper === 3 && (
          <>
            <ResourceUpload
              resourcesList={resourcesList}
              setResourcesList={setResourcesList}
              required={false}
              label={"Resources : "}
            />
            <div className="flex flex-wrap items-center lg:justify-between">
              <div className="flex justify-end lg:w-3/12"></div>
              <div className="flex justify-end w-full lg:w-3/12">
                <button
                  className="btn-sea-green text-xl w-full"
                  onClick={saveResources}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {openUpdateTestiModal && (
        <EditTestimonial
          testimonialId={testimonialId}
          setRefresher={setRefresher}
          onClickClose={() => setOpenUpdateTestiModal(false)}
        />
      )}
      {notificationModal && (
        <NotificationConfirmation
          yesHandler = {() => {
            SendProductNotification(productId);
            setNotificationModal(false);
          }
          }
          noHandler = {() => {
            setNotificationModal(false);
          }
          }
          keyword={"product"}
        />
      )}
    </div>
  );
}

export default ProductsAdd;
