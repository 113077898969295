export const FormatNumber = (number) => {
    // return new Intl.NumberFormat('en-US').format(number);
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  export const camelToTitleCase = (camelCaseString) => {
    // Use a regex pattern to handle all transitions:
    // - Lowercase to Uppercase (e.g., "lastDays" -> "last Days")
    // - Letter to Digit (e.g., "last7" -> "last 7")
    // - Digit to Letter (e.g., "7Days" -> "7 Days")
    const result = camelCaseString.replace(/([a-z])([A-Z])|([a-zA-Z])(\d)|(\d)([a-zA-Z])/g, '$1$3$5 $2$4$6');
  
    // Split by space and capitalize each word
    return result
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  export const toCamelCase = (str) => {
    return str
      .toLowerCase()                         
      .split(' ')                              
      .map((word, index) => 
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )                                        
      .join('');                             
  }