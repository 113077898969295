import React, { useEffect, useState } from 'react'
import { Line } from "react-chartjs-2";
import LocalLoader from "../../general/LocalLoader"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getMethod } from '../../../utils/apiMethod';
import { toastError } from '../../../utils/Toasters';
// Register the necessary Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  // Data and options for the line chart
//   const data = {
//     labels: [
//       "Jan 1 - Jan 7",
//       "Jan 8 - Jan 14",
//       "Jan 15 - Jan 21",
//       "Jan 22 - Jan 28",
//       "Feb 5 - Feb 11",
//       "Feb 12 - Feb 18",
//       "Feb 19 - Feb 25",
//       "Feb 26 - Mar 3",
//       "Mar 4 - Mar 10",
//       "Mar 11 - Mar 17",
//       "Mar 18 - Mar 24",
//     ],
//     datasets: [
//       {
//         data: [240, 800, 500, 600, 1000, 1200, 1400, 1800, 1500, 1300, 900], // Adjust values to match your screenshot
//         fill: true,
//         backgroundColor: "rgba(0, 196, 140, 0.1)", // Light green fill below the line
//         borderColor: "#00c48c", // Green border color for the line
//         borderWidth: 2, // Thickness of the line
//         pointBackgroundColor: "#00c48c", // Color of the data points
//         pointBorderColor: "#ffffff", // White border around the points
//         tension: 0.1, // Smooth curve for the line
//       },
//     ],
//   };

const data = {
    datasets: [
      {
        data: [
          { x: "Jan 1 - Jan 7", y: 240 },
          { x: "Jan 8 - Jan 14", y: 800 },
          { x: "Jan 15 - Jan 21", y: 500 },
          { x: "Jan 22 - Jan 28", y: 600 },
          { x: "Feb 5 - Feb 11", y: 1000 },
          { x: "Feb 12 - Feb 18", y: 1200 },
          { x: "Feb 19 - Feb 25", y: 1400 },
          { x: "Feb 26 - Mar 3", y: 1800 },
          { x: "Mar 4 - Mar 10", y: 1500 },
          { x: "Mar 11 - Mar 17", y: 1300 },
          { x: "Mar 18 - Mar 24", y: 900 },
        ], // Use the new format for x and y values
        fill: true,
        backgroundColor: "rgba(0, 196, 140, 0.1)", // Light green fill below the line
        borderColor: "#00c48c", // Green border color for the line
        borderWidth: 2, // Thickness of the line
        pointBackgroundColor: "#00c48c", // Color of the data points
        pointBorderColor: "#ffffff", // White border around the points
        tension: 0.1, // Smooth curve for the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Maintain the correct aspect ratio
    scales: {
      y: {
        ticks: {
          display: true, // Display y-axis ticks
          padding: 10,
          font: {
            size: 12,
          },
        },
        grid: {
          display: true,
          borderDash: [8, 4], // Dashed grid line pattern
          color: "rgba(0, 0, 0, 0.1)", // Light gray color for grid lines
        },
        // suggestedMin: 0, // Minimum value of the y-axis
        // suggestedMax: 2000, // Adjust maximum value based on data
      },
      x: {
        ticks: {
          display: true, // Show x-axis labels
          padding: 10,
          font: {
            size: 12,
          },
        },
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the default legend
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff", // White background for tooltips
        titleColor: "#222222", // Black title color
        bodyColor: "#222222", // Black body text color
        borderColor: "#e0e0e0", // Light gray border for tooltips
        borderWidth: 1, // Border width for tooltips
        padding: 10,
      },
    },
  };
  
function StatsGraph({category}) {
    const [chartData, setChartData] = useState({
        datasets: [],
      });

      const [loading, setLoading] = useState(true);

      // Step 2: Map category names to API URLs (adjust as necessary)
      const apiEndpoints = {
        followers: {url : 'analytics/followersGraphs', y: "followerCount"},
        views: {url: 'analytics/viewsGraphs', y: "pageViews"},
        timeSpent: {url: 'analytics/avgTimeGraphs', y:"avgTime"},
        bounceRate: {url:'analytics/bounceRateGraphs', y:"bounceRate"},
        meetingsBooked: {url:'analytics/bookMeetGraphs', y:"clickCount"},      
      };

      useEffect(() => {
        async function fetchData() {
          setLoading(true);
          try {
            // Fetch data from the corresponding API based on the category
            const active = apiEndpoints[category];
            const response = await getMethod(active?.url);
            // Check if the response is successful and has data
            if (response.status && response.data) {
              // Transform the data into the format required by Chart.js
              const formattedData = response.data.map((item) => ({
                x: item.week,
                y: item[active?.y],
              }));
    
              // Update the chart data state
              setChartData({
                labels: response.data.map(item => item.week),
                datasets: [
                  {
                    label: category, // Use category name as the label
                    data: formattedData,
                    fill: true,
                    backgroundColor: "rgba(0, 196, 140, 0.1)", // Light green fill below the line
                    borderColor: "#00c48c", // Green border color for the line
                    borderWidth: 2, // Thickness of the line
                    pointBackgroundColor: "#00c48c", // Color of the data points
                    pointBorderColor: "#ffffff", // White border around the points
                    tension: 0.1, // Smooth curve for the line
                  },
                ],
              });
            }
          } catch (error) {
            console.error("Error fetching chart data:", error);
            toastError(error.message)
          }
          setLoading(false);
        }
    
        // Call fetchData whenever category changes
        if (category && apiEndpoints[category]) {
          fetchData();
        }
      }, [category]);

  return (
    <div className="p-5 bg-white rounded-b-xl">
    <div className="flex items-center justify-center relative" style={{ height: "400px" }}>
        {
            loading? <LocalLoader/> :
      <Line data={chartData} options={options} />
        }
    </div>
  </div>
  )
}

export default StatsGraph