import { useContext, useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError } from "../../../utils/Toasters";
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons'


function Sources({onEditClick}) {

    const {setLoading,profileStatus} = useContext(StateContext);

    const [website, setWebsite] = useState("");
    const [customerPortalLink, setCustomerPortalLink] = useState("");
    const [eventPageLink, setEventPageLink] = useState("");
    const [socialLinks, setSocialLinks] = useState([]);
    const [careerPageLink, setCareerPageLink] = useState("");
     
    useEffect(() => {
        setLoading(true);
    window.scrollTo(0,0)

        getMethod(`source/getMySourceInformation`)
          .then((res) => {
            setLoading(false)
            if (res?.status) {
                if(res.exists){

                    console.log("Response source : ", res.data)
                    setWebsite(res.data.source.website);
                    setCustomerPortalLink(res.data.source.customerPortalLink || "-");
                    setEventPageLink(res.data.source.eventPageLink || "-");
                    setCareerPageLink(res?.data?.source?.careersLink || "-");
                    setSocialLinks([ ...res.data.socialLinks]);
                }
          
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);

    return (
      <div className="p-5 lg:p-8 category-content-wrapper-div">
        {/* header */}
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex flex-col">
            <div className="font-medium text-[28px]">Sources</div>
            <div className="text-grey-2 text-[16px] capitalize">
              Get users connected to your company.
            </div>
          </div>
          {
                profileStatus !== "1" &&
          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" onClick={onEditClick}>
              Edit
            </button>
          </div>
          }
        </div>

        <div className="flex flex-col gap-5">
          <div className="xs:flex-col lg:flex items-center">
            <div className="lg:w-1/3 font-medium text-black-1">Website</div>
            <div className="lg:w-2/3 font-light">
              {website === "" ? "-" : website}
            </div>
          </div>
          <div className="xs:flex-col lg:flex  items-center">
            <div className="lg:w-1/3 font-medium text-black-1">Events Page</div>
            <div className="lg:w-2/3 font-light flex gap-5">
              <div>{eventPageLink === "" ? "-" : eventPageLink}</div>
            </div>
          </div>
          <div className="xs:flex-col lg:flex  items-center">
            <div className="lg:w-1/3 font-medium text-black-1">
              Customer Portal
            </div>
            <div className="lg:w-2/3 font-light break-all">
              {customerPortalLink === "" ? "-" : customerPortalLink}
            </div>
          </div>
          <div className="xs:flex-col lg:flex  items-center">
            <div className="lg:w-1/3 font-medium text-black-1">
              Careers Page
            </div>
            <div className="lg:w-2/3 font-light">
              {careerPageLink ? careerPageLink:"-" }
            </div>
          </div>
          <div className="xs:flex-col lg:flex  items-center">
            <div className="lg:w-1/3 font-medium text-black-1">
              Social Networks
            </div>

            <div className="lg:w-2/3 font-light flex gap-3">
              {socialLinks?.length > 0
                ? socialLinks.map((socialLinks) => {
                    return (
                      //    <img key={index} src="/assets/cancel.svg" alt="" />
                      <SocialIcon
                      url={`www.${(socialLinks?.name).toLowerCase()}.com`}
                        href={socialLinks?.link}
                        as={"a"}
                        target="_blank"
                      />
                    );
                  })
                : "-"}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Sources;
  