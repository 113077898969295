import { useContext, useEffect, useState } from "react";
import InputField from "../../general/NewInputField";
import { toastError, toastSuccess } from "../../../utils/Toasters";
import { deleteMethod, getMethod, postMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { ValidateUrl } from "../../../utils/urlValidator";
import { getOnBoardCookie } from "../../../auth/cookies";
import { useNavigate } from "react-router";
function SourcesEdit({ onBackClick }) {
  const [website, setWebsite] = useState("");
  const [customerPortalLink, setCustomerPortalLink] = useState("");
  const [eventPageLink, setEventPageLink] = useState("");
  const [careerPageLink, setCareerPageLink] = useState("");
  const [socialLinks, setSocialLinks] = useState([{ name: "", link: "" }]);
  const navigate = useNavigate()

  const addSocialLink = () => {
    setSocialLinks([...socialLinks, { name: "", link: "" }]);
  };
  const handleSocialLinkInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...socialLinks];
    list[index][name] = value;
    setSocialLinks(list);
  };

  const {setLoading} = useContext(StateContext);

  const submitHandler = (e) => {

    e.preventDefault();

    try{
      let socialLinksArray = socialLinks.map((socialLink) => {
        return  socialLink.link ;
      })
      ValidateUrl([website, customerPortalLink,eventPageLink, careerPageLink], "Company Information")
      ValidateUrl(socialLinksArray, "Social")


    const raw = JSON.stringify({
      website: website,
      customerPortalLink: customerPortalLink,
      eventPageLink: eventPageLink,
      careersLink: careerPageLink,
      socialLinks: socialLinks.map((socialLink) => {
        return { name: socialLink.name, link: socialLink.link };
      }),
    });
    // getMethod(`source/getMySourceInformation`)

    const cookieToken = getOnBoardCookie();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + cookieToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/source/getMySourceInformation`,
      requestOptions
    )
      .then((res) => {
        return res?.json();
      })
      .then((response) => {
        if (response?.exists === true) {
          // update
          postMethod(`source/updateSource`, raw, navigate)
            .then((res) => {
              if (res?.status === true) {
                toastSuccess(res?.message);
                onBackClick();
              }
            })
            .catch((error) => {
              // setLoading(false);
              toastError(error?.message);
              console.log("error.message: ", error.message);
            });
        } else {
          // update
          postMethod(`source/addSource`, raw)
            .then((res) => {
              if (res?.status === true) {
                toastSuccess(res?.message);

                onBackClick();
              }
            })
            .catch((error) => {
              // setLoading(false);
              toastError(error?.message);
              console.log("error.message: ", error?.message);
            });
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error?.message);
        console.log("error.message: ", error?.message);
      });

    }catch(e){
      toastError(e?.message)
    }
    
  };

  useEffect(() => {
    setLoading(true);
  window.scrollTo(0,0)

    getMethod(`source/getMySourceInformation`)
      .then((res) => {
        setLoading(false)
        if (res?.status) {
            if(res?.exists){
                setWebsite(res?.data?.source?.website);
                setCustomerPortalLink(res?.data?.source?.customerPortalLink);
                setEventPageLink(res?.data?.source?.eventPageLink);
                setCareerPageLink(res?.data?.source?.careersLink);
               
                // setLinksData([...res.data.socialLinks])
                if(res.data.socialLinks.length > 0 ){
                  setSocialLinks([...res?.data?.socialLinks]);
                }
                else{

                  setSocialLinks([...socialLinks, ...res.data.socialLinks]);
                }
               
            }
      
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  const handleDeleteResource = (socialLinkId, idx) => {

    if(socialLinkId){

      deleteMethod(`source/deleteSocialLink/${socialLinkId}`)
      .then((res) => {
        if (res.status === true) {
          //   setOpen(false);
          setSocialLinks(
            socialLinks.filter((socialLink) => socialLink.id !== socialLinkId)
          );
            toastSuccess("Social Links deleted!");
        }
      })
      .catch((error) => {
        toastError(error.message);
        console.log("Error: ", error.message);
      });

    }else{

      setSocialLinks(prevFile => {
        const newArray = [...prevFile];
        newArray.splice(idx, 1);
        return newArray;
      });

      // let newArray = [...resourcesList];
   
      // newArray.splice(idx,1)
      // setResourcesList(newArray)

      // console.log("NEW ARRAY: ", newArray)

      // console.log("Inside delete fn :",resourcesList)
      // toastInfo("Social Link eliminated!");

    }

  
  };

  return (
    <form className="p-5 lg:p-8 category-content-wrapper-div" onSubmit={submitHandler}>
      {/*  Header */}
      <div className="flex flex-col gap-3">
        <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
          <div className="flex gap-3">
            <div onClick={onBackClick} className="cursor-pointer">
              <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
            </div>

            <div className="flex flex-col">
              <div className="font-medium text-[28px]">Edit Sources</div>
              <div className="text-grey-2 capitalize">Add sources details</div>
            </div>
          </div>

          <div className="flex lg:justify-end mt-5 lg:mt-0">
            <button className="btn-sea-green-thick" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Form */}

      <div className="flex flex-col gap-5">
        {/* SOURCES DETAILS */}
        <div className="form-outline-wrapper-div p-8 pt-5 gap-8">
          {/* Input fields */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <InputField
              label="Website"
              placeholder="Link"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Customer Portal"
              placeholder="Link"
              value={customerPortalLink}
              onChange={(e) => setCustomerPortalLink(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Events Page"
              placeholder="Event Page Link"
              value={eventPageLink}
              onChange={(e) => setEventPageLink(e.target.value)}
              width={"w-full"}
              optional={false}
            />
            <InputField
              label="Careers Page"
              placeholder="Careers Link"
              value={careerPageLink}
              onChange={(e) => setCareerPageLink(e.target.value)}
              width={"w-full"}
              optional={false}
            />
          </div>
        </div>

        {/* Social Media Details */}
        <div className="form-outline-wrapper-div p-5 gap-2">
          <div>Social Media Links:</div>

          {/* Input fields */}
          {
            socialLinks.map((socialLink, index)=>{

              return(

                <div key={index} className="relative flex flex-col gap-2 rounded-xl border border-grey-8 p-3">
                   { socialLinks.length > 1  ? <div
                                role="button"
                                className="delete-btn d-flex items-center justify-center p-2"
                                onClick={() => handleDeleteResource(socialLink.id , index)}
                                >
                                <img className="" src="assets/cancel.svg" alt="cancel" />
                              </div>: ""}
                <div> 
                  Social Media {index + 1}<span className="text-orange-1">*</span>
                </div>
    
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                  <div className="flex items-center rounded-lg border border-grey-7 h-12">
                    <input
                      className={`focus:border-transparent outline-none px-5 w-full h-full ${socialLink.id? "cursor-not-allowed" : ""}`}
                      placeholder="Name"
                      value={socialLink.name}
                      onChange={e => handleSocialLinkInputChange(index, e)}
                      name="name"
                      disabled = {socialLink?.id? true : false}
                      required={socialLinks.length < 2}

                
                    />
                  </div>
                  <div className="flex w-full items-center rounded-lg border border-grey-7 h-12">
                    <input
                      className="focus:border-transparent outline-none mx-5 w-full h-full"
                      placeholder="Link"
                      value={socialLink.link}
                      onChange={e => handleSocialLinkInputChange(index, e)}
                      name="link"
                  
                    />
                  </div>
                </div>
              </div>

              )

            })
          }
       

          <div className="text-sea-green font-medium cursor-pointer" onClick={addSocialLink}>Add more</div>
        </div>
      </div>

      {/* Save changes button */}

      <div className="flex flex-row-reverse">
        <div className="flex lg:justify-end w-full lg:w-2/12">
          <button
            className="btn-sea-green text-xl w-full"
           type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default SourcesEdit;