import React, { useContext, useEffect, useState } from "react";
import { getMethod, postFormMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess, toastWarn } from "../../../utils/Toasters";
import { postMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { useNavigate } from "react-router";
import DataNotFound from "../../general/DataNotFound";

function Privacy({ onEditClick }) {
  // const [loading, setLoading] = useState(false);

const navigate = useNavigate()
  const { setLoading, setPrivacyLoading, profileStatus } = useContext(StateContext);
  const [policy, setPolicy] = useState("");
  const [url, setUrl] = useState("");
  const [pdf, setPdf] = useState(null);
  const [uploadMethod, setUploadMethod] = useState("url")

  const urlHandler = (e) => setUrl(e.target.value);


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setPdf(selectedFile);
    } 
    // else {
    //   setPdf(null)
    // }
  };

  const urlSubmitHandler = (e) => {
    e.preventDefault();
    setPrivacyLoading(true);

    try{

      var formdata = new FormData()
      if(uploadMethod === 'url'){
        formdata.append('url', url)
       
      }
      if(uploadMethod === 'pdf'){
        formdata.append('pdf', pdf)
        
      }
      postFormMethod(`company/companyPrivacyPolicy`, formdata)
      .then((res) => {
        setPrivacyLoading(false);
        if (res?.status) {
          setPolicy(res.data);
          toastSuccess(res?.message)
        }else{
          toastError(res?.message)
        }
      })
      .catch((error) => {
        setPrivacyLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });

    }catch(error){
      setPrivacyLoading(false);
      console.log("Error : ", error.message);
      toastError(error.mesage)
    }

   

  };

  const publishHandler = () => {
    postMethod(`company/reviewProfile`)
    .then((res) => {
      // setLoading(false);
      if (res.status === true) {
        //   setLoading(false);

      //  setProfileStatus("1")
        toastSuccess(res.message);
      }
      else{
        toastError(res.message)
       
      }
      navigate("/company?activePage=dashboard")
    })
    .catch((error) => {
      // setLoading(false);
      toastError(error.message);
    });
  }
  useEffect(() => {
    setLoading(true);
   
      window.scrollTo(0,0)
 
    getMethod(`privacy`)
      .then((res) => {
        setLoading(false);

        if (res?.status === true) {

            setPolicy(res?.data?.content);
          
          setUrl(res?.data?.url);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  return (
    <div className="flex flex-col gap-10">

    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {
            profileStatus !== "1" &&
      <form
        onSubmit={urlSubmitHandler}
        className="xs:flex-col lg:flex lg:justify-between items-center"
      >
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="font-medium text-[28px]">Privacy Policy URL</div>
            <div className="text-grey-2 text-[16px] italic">
              Enter the link to your privacy policy here
            </div>
          </div>

          <div className="flex gap-5 m-5">
          <div className="flex gap-2 items-center">
                <input
                  type="radio"
                  id="url"
                  name="privacyUpload"
                  value="url"
                  checked={uploadMethod === "url"}
                  onChange={(e)=> setUploadMethod(e.target.value)}
                  className="cursor-pointer"
                />
                <label className="cursor-pointer">Enter Privacy URL</label>
              </div>
          <div className="flex gap-2 items-center">
                <input
                  type="radio"
                  id="pdf"
                  name="privacyUpload"
                  value="pdf"
                  checked={uploadMethod === "pdf"}
                  onChange={(e)=> setUploadMethod(e.target.value)}
                  className="cursor-pointer"
                />
                <label className="cursor-pointer">Upload PDF</label>
              </div>
          </div>


        <div className="m-5">


        {
            uploadMethod === 'url' &&
          <input
            required
            placeholder="Enter Privacy Policy URL"
            className="h-full border rounded-lg p-2 border-grey-8 outline-none w-full lg:w-[450px]"
            value={url}
            onChange={urlHandler}
          />
          }
          {
            uploadMethod === 'pdf' &&
            <input
            type="file"
            accept="application/pdf"
            onChange={ handleFileChange }
            className="cursor-pointer block w-full text-sm text-grey-10 file:mr-4 file:py-2 file:px-4
            file:rounded-lg file:border-0
            file:text-sm file:font-semibold
              hover:file:cursor-pointer"
          />
          }

        </div>

        </div>
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button type="submit" className="btn-sea-green-thick">
            Save
          </button>
        </div>
      </form>
      }

      {/* header */}
      {    policy?.length > 0 ? <>
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">Privacy Policy Summary</div>
          <div className="text-grey-2 text-[16px] italic">
            Below is the AI generated summary of your privacy policy. Please
            review and edit if required.
          </div>
        </div>
        {
              profileStatus !== "1" &&
        <div className="flex lg:justify-end mt-5 lg:mt-0 ">
          <button className="btn border border-sea-green px-10 py-3 rounded-md text-sea-green" onClick={onEditClick}>
            Edit
          </button>
        </div>
        }
      </div>

      {/* Body */}
      {/* {
    policy === undefined ? (

    
      <div className="flex flex-col items-center justify-center">
      <div className="w-1/2 rounded-full relative h-60 overflow-hidden">
          <img src="assets/not-found.png" alt="no data found" className="object-center w-full h-full"/>
      </div>
      <div className="text-xl">
          No Data found!
      </div>
    </div>

    ) : ( */}

    
   
        <div className="" dangerouslySetInnerHTML={{ __html: policy }}></div>
      </> : <DataNotFound/>
    }
    </div>
    {
  profileStatus === "1" ||  profileStatus === "2" ?
   "" :  <div className="flex justify-center w-full">
   <button className="btn-sea-green w-1/3 md:w-1/6" onClick={publishHandler}>Publish</button>
 </div>
}
    </div>

  );
}

export default Privacy;
