import { useContext, useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";
import { toastError } from "../../../utils/Toasters";

function Finance({ onEditClick }) {
  const { setLoading,profileStatus } = useContext(StateContext);

  const [financeDetails, setFinanceDetails] = useState({
    ipoStatus: "-",
    latestFundingRound: "-",
    averagePurchasePrice: 0,
    financeLink: "-",
    revenuePerEmployee: 0,
    revenuePerClient: 0,
    latestValuation: 0,
    annualRevenue: 0,
    totalFunding: 0,
  });


  const FormatNumber = (number) => {
    // return new Intl.NumberFormat('en-US').format(number);
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  useEffect(() => {
    setLoading(true);

    window.scrollTo(0,0)
 
    getMethod(`finance/getMyFinanceInformation`)
      .then((res) => {
        setLoading(false);
        if (res?.status === true) {
          if (res.exists === true) {
            console.log("Response finance : ", res.data.latestFundingRound);
            const updatedFinanceDetails = {
              ...financeDetails,
              ipoStatus: res.data?.ipoStatus,
              latestFundingRound: res.data?.latestFundingRound,
              averagePurchasePrice: res.data?.averagePurchasePrice,
              financeLink: res.data?.financeLink,
              revenuePerEmployee: res.data?.revenuePerEmployee,
              revenuePerClient: res.data?.revenuePerClient,
              latestValuation: (res?.data?.latestValuation ? FormatNumber(res.data?.latestValuation) : 0),
              annualRevenue: res.data?.annualRevenue,
              totalFunding: (res.data?.totalFunding ? FormatNumber(res.data?.totalFunding) : 0),
            };
            setFinanceDetails(updatedFinanceDetails);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);  
        console.log("error.message: ", error.message);
      });
  }, []);
  return (
    <div className="p-5 lg:p-8 category-content-wrapper-div">
      {/* header */}
      <div className="xs:flex-col md:flex lg:justify-between lg:items-center">
        <div className="flex flex-col">
          <div className="font-medium text-[28px]">Finance</div>
          <div className="text-grey-2 lg:w-3/4 text-[14px]">
            Add your financial details as you feel comfortable, the goal is for
            you to control the facts of your financial data vs. the internet
            telling your story. If you are not comfortable sharing financial
            details, you can opt for "N/A" as well.
          </div>
        </div>
        {
              profileStatus !== "1" &&
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={onEditClick}>
            Edit
          </button>
        </div>
        }
      </div>

      <div className="flex flex-col gap-5">
        <div className="xs:flex-col md:flex items-center">
          <div className="md:w-1/3 font-medium text-black-1">IPO Status</div>
          <div className="md:w-2/3 font-light capitalize">
            {financeDetails?.ipoStatus}
          </div>
        </div>

        {financeDetails?.ipoStatus?.toLowerCase() === "public" && (
          <div className="xs:flex-col md:flex items-center">
            <div className="md:w-1/3 font-medium text-black-1">
              Yahoo! Finance Page
            </div>
            <div className="md:w-2/3 font-light">
              <p className="break-all max-w-full">
                {financeDetails?.financeLink}
              </p>
            </div>
          </div>
        )}

        <div className="xs:flex-col md:flex items-center">
          <div className="md:w-1/3 font-medium text-black-1">
            Latest Funding round
          </div>
          <div className="md:w-2/3 font-light capitalize">
            {financeDetails?.latestFundingRound}
          </div>
        </div>

        <div className="xs:flex-col md:flex items-center">
          <div className="md:w-1/3 font-medium text-black-1">Total Funding</div>
          <div className="md:w-2/3 font-light flex gap-5">
            {financeDetails?.totalFunding ? (
              <div>{`$${financeDetails?.totalFunding}`}</div>
            ) : (
              <div>{`N/A`}</div>
            )}
          </div>
        </div>

        <div className="xs:flex-col md:flex items-center">
          <div className="md:w-1/3 font-medium text-black-1">
            Latest Valuation
          </div>
          {financeDetails?.latestValuation?<div className="md:w-2/3 font-light">
            {`$${financeDetails?.latestValuation}`}
          </div>
          :<div className="md:w-2/3 font-light">
            {`N/A`}
          </div>}
        </div>

        <div className="xs:flex-col md:flex items-center">
          <div className="md:w-1/3 font-medium text-black-1">
            Annual Revenue
          </div>
          <div className="md:w-2/3 font-light capitalize">
            {financeDetails?.annualRevenue}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finance;
