
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../Context/StateContext";
import { getMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import AccessControl from "./AccessControl";
import ManageInvitations from "./ManageInvitations";

function ProfileManagement() {
  const [category, setCategory] = useState("invitations");
  const [showUserModal, setShowUserModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const {setLoading} = useContext(StateContext);

  const getRoles = async () => {
    setLoading(true)
    try {
      let response = await getMethod(`admin/role`);
      if (response?.status == true) {
        setRoles(response?.data);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toastError(error?.message);
    }
  };

  const getAccounts = async () => {
    setLoading(true)
    try {
      let response = await getMethod(`admin/account`);
      if (response?.status == true) {
        setAccounts(response?.data?.results);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toastError(error?.message);
    }
  }

//   useEffect(() => {
//     //fetch roles
//     // getRoles();
//   }, []);

  const AddNewUser = () => {
    setShowUserModal(true);
  };
  const AddNewRole = () => {
    setShowRoleModal(true);
  };
  return (
    <div className="flex flex-col gap-5 bg-none">
      <div className="flex justify-between">
        <div className="flex gap-10 text-sea-green font-bold text-lg">
          <div
            className={`py-2 cursor-pointer ${
              category === "invitations" && "border-b-4 border-sea-green"
            }`}
            onClick={() => setCategory("invitations")}
          >
            Manage Invitations
          </div>
          <div
            className={`py-2 cursor-pointer ${
              category === "control" && "border-b-4 border-sea-green"
            }`}
            onClick={() => setCategory("control")}
          >
           Access Control
          </div>
        </div>
      </div>

      {
        {
            invitations: <ManageInvitations/>,
          control: <AccessControl/>,
        }[category]
      }
    </div>
  );
}

export default ProfileManagement;
