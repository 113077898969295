// import React, { useEffect, useState } from "react";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
// import { camelToTitleCase } from "../../../utils/stringNumberFormat";
// import { getMethod } from "../../../utils/apiMethod";

// // Register the necessary components with Chart.js
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const Engagement = () => {
//   // Define chart data
//   const data = {
//     labels: [
//       "Products",
//       "People",
//       "Finance",
//       "Metrics",
//       "Content",
//       "News",
//       "Awards",
//       "Reference",
//       "Feedback",
//       "Privacy",
//     ],
//     datasets: [
//       {
//         label: "Average Engagement Time",
//         data: [4, 3, 1.5, 2, 4.5, 5, 7, 3.5, 2.5, 1], // Replace with your data
//         backgroundColor: [
//           "rgba(0, 0, 0, 0.2)", // Default light gray color
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//           "#41b6ac", // Distinct color for "Awards"
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//           "rgba(0, 0, 0, 0.2)",
//         ],
//         borderRadius: 4, // Rounded corners for bars
//         barPercentage: 0.5, // Make bars thinner by reducing the barPercentage
//         categoryPercentage: 0.6, // Increase the spacing between bars within each category

//       },
//     ],
//   };

//   // Define chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false, // Adjusts the aspect ratio to fit the container
//     scales: {
//       y: {
//         ticks: {
//           stepSize: 1, // Defines the interval between ticks on the y-axis
//           font: {
//             size: 12,
//           },
//           color: "#222222",
//           padding: 10, // Add spacing around tick labels
//         },
//         grid: {
//           display: false, // Show horizontal grid lines
//           drawBorder: false,
//           lineWidth: 0.5, // Thin lines
//           color: "rgba(0, 0, 0, 0.1)", // Light gray color for grid lines
//         },
//         title: {
//             display: true,
//             text: "time in min", // Y-axis label
//             // font: {
//             //   size: 14,
//             //   weight: "bold",
//             // },
//             color: "#333333",
//             padding: {
//               top: 10,
//               bottom: 20,
//             },
//           },
//         suggestedMin: 0,
//         suggestedMax: 8, // Ensure the maximum value matches the chart data
//       },
//       x: {
//         ticks: {
//           font: {
//             size: 12,
//           },
//           color: "#222222",
//           padding: 10, // Add spacing around tick labels
//         },
//         grid: {
//           display: false, // Hide vertical grid lines
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: false, // Hide legend by default
//       },
//       tooltip: {
//         enabled: true,
//         backgroundColor: "#ffffff", // White tooltip background
//         titleColor: "#222222",
//         bodyColor: "#222222",
//         borderColor: "#e0e0e0",
//         borderWidth: 1,
//         padding: 10,
//       },
//     },
//   };

//   const [openDropdown, setOpenDropdown] = useState(false);
//   const [duration, setDuration] = useState("lastWeek");

//   async function fetchData() {
//     const response = await getMethod(`analytics/sectionStats?duration=${duration}`)
//     console.log("ENGAGEMRNT DATA : ", response)
//   }

//   useEffect(() => {
  
//     fetchData();
//   }, [])
  

//   return (
//     <div className="flex flex-col gap-2 box-shadow4 rounded-2xl bg-white p-5">

//       <div className="flex justify-between items-center">

//         <div className="font-semibold text-lg">

//         Average Engagement Time in each section

//         </div>

//          {/* Sort Dropdown */}
//       <div className="flex items-center mb-5">
//         <p>Time Range: </p>
//         <div
//           className="ml-2 p-2 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm relative"
//           onClick={() => setOpenDropdown(!openDropdown)}
//         >
//          {camelToTitleCase(duration)}
//           <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
//             {/* Dropdown Arrow */}
//             <path
//               d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
//               fill="#222222"
//             />
//           </svg>
//           {/* Dropdown */}
//           {openDropdown && (
//             <div className="ml-2 px-10 py-2 border border-grey-7 bg-white rounded-md cursor-pointer flex flex-col items-center shadow-sm absolute -bottom-3 -right-28 gap-2">
//             {/* <div onClick={()=>setDuration("last7Days")}>
//             Last 7 Days
//             </div> */}
//             <div onClick={()=>setDuration("lastWeek")}>
//             Last Week
//             </div>
//             <div onClick={()=>setDuration("last15Days")}>
//             Last 15 Days
//             </div>
//             <div onClick={()=>setDuration("last60Days")}>
//             Last 60 Days
//             </div>
//             <div onClick={()=>setDuration("last90Days")}>
//             Last 90 Days
//             </div>
//             </div>
//           )}
//         </div>
//       </div>

//       </div>

//       <div className="flex flex-col">
//       {/* Chart Container */}
//       <div className="p-5" style={{ height: "400px" }}>
//         <Bar data={data} options={options} />
//       </div>
//     </div>

//     </div>
  
//   );
// };

// export default Engagement;


import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { camelToTitleCase } from "../../../utils/stringNumberFormat";
import { getMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";

// Register the necessary components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Engagement = () => {
  const [engagementData, setEngagementData] = useState([]);
  const {topEngagedSection, setTopEngagedSection} = useContext(StateContext);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [duration, setDuration] = useState("allTime");

  // Function to fetch data from API
  async function fetchData() {
    const response = await getMethod(`analytics/sectionStats?duration=${duration}`);
    if (response.status) {
      setEngagementData(response.data);
      setTopEngagedSection(response.topEngaged);
    }
  }

  useEffect(() => {
    fetchData();
  }, [duration]);

  // Prepare data for the chart
  const labels = [
    "Product",
    "People",
    "Finance",
    "Metrics",
    "Content",
    "News",
    "Awards",
    "Reference",
    "Feedback",
    "Privacy"
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Average Engagement Time",
        data: labels.map(label => engagementData[label] || 0), // Map API data to chart labels
        backgroundColor: labels.map(label =>
          label === topEngagedSection ? "#41b6ac" : "rgba(0, 0, 0, 0.2)" // Highlight top engaged section
        ),
        borderRadius: 4,
        barPercentage: 0.5,
        categoryPercentage: 0.6,
      },
    ],
  };

  // Chart options remain the same
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          font: {
            size: 12,
          },
          color: "#222222",
          padding: 10,
        },
        grid: {
          display: false,
          drawBorder: false,
          lineWidth: 0.5,
          color: "rgba(0, 0, 0, 0.1)",
        },
        title: {
          display: true,
          text: "time in seconds",
          color: "#333333",
          padding: {
            top: 10,
            bottom: 20,
          },
        },
        suggestedMin: 0,
        suggestedMax: 8,
      },
      x: {
        ticks: {
          font: {
            size: 12,
          },
          color: "#222222",
          padding: 10,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff",
        titleColor: "#222222",
        bodyColor: "#222222",
        borderColor: "#e0e0e0",
        borderWidth: 1,
        padding: 10,
      },
    },
  };

  return (
    <div className="flex flex-col gap-2 box-shadow4 rounded-2xl bg-white p-5">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-lg">
          Average Engagement Time in each section
        </div>

        {/* Sort Dropdown */}
        <div className="flex items-center mb-5">
          <p>Time Range: </p>
          <div
            className="ml-2 p-2 border border-grey-7 rounded-md cursor-pointer flex items-center shadow-sm relative"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
                  {duration === "last7Days" ? "Last 7 Days" : camelToTitleCase(duration)}
            <svg width="16" height="16" className="ml-2" viewBox="0 0 23 22">
              {/* Dropdown Arrow */}
              <path
                d="M11.8 14.4L5.6 8.2C5.3 7.9 5.1 7.4 5.4 7.1C5.7 6.8 6.2 7 6.5 7.4L12 12.9L17.5 7.4C17.8 7 18.3 6.8 18.6 7.1C18.9 7.4 18.7 7.9 18.4 8.2L12.2 14.4C12 14.6 11.8 14.6 11.8 14.4Z"
                fill="#222222"
              />
            </svg>
            {/* Dropdown */}
            {openDropdown && (
              <div className="px-2 py-2 border border-grey-7 bg-white rounded-md cursor-pointer flex flex-col items-left shadow-sm absolute -bottom-52 right-0.5 gap-2 w-28 z-30">
                {/* <div onClick={() => setDuration("last7Days")}>
                  Last 7 Days
                </div> */}
                <div onClick={() => setDuration("last7Days")}>
                  Last 7 Days
                </div>
                <div onClick={() => setDuration("last15Days")}>
                  Last 15 Days
                </div>
                <div onClick={() => setDuration("last30Days")}>
                  Last 30 Days
                </div>
                <div onClick={() => setDuration("last60Days")}>
                  Last 60 Days
                </div>
                <div onClick={() => setDuration("last90Days")}>
                  Last 90 Days
                </div>
                <div onClick={() => setDuration("allTime")}>
                 All Time
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {/* Chart Container */}
        <div className="p-5" style={{ height: "400px" }}>
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Engagement;
