import { postMethod } from "./apiMethod";
import { toastError, toastSuccess } from "./Toasters";

export const SendProductNotification = (id) => {
    // sendNotification("product", id)
    try {
        var raw = {
            types : ["product"],
            productId: id.toString()
        }
        raw = JSON.stringify(raw);
  
        postMethod(`notification/sendNotification`, raw)
          .then((res) => {
            if (res.status === true) {
              console.log("NOTIFICATION RES : ", res);
              toastSuccess(res.message);
            } else {
              toastError(res.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      } catch (error) {
        toastError(error.message);
      }
   };


export const SendPeopleNotification = (notiType, memberName) => {
    try {
        var raw = {
            types : [notiType],
            memberName : memberName
        }
      
        raw = JSON.stringify(raw);
  
        postMethod(`notification/sendNotification`, raw)
          .then((res) => {
            if (res.status === true) {
              console.log("NOTIFICATION RES : ", res);
              toastSuccess(res.message);
            } else {
              toastError(res.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      } catch (error) {
        toastError(error.message);
      }
   };
export const SendAwardNotification = (awardName) => {
    try {
        var raw = {
            types : ["award"],
            awardName : awardName
        }
      
        raw = JSON.stringify(raw);
  
        postMethod(`notification/sendNotification`, raw)
          .then((res) => {
            if (res.status === true) {
              console.log("NOTIFICATION RES : ", res);
              toastSuccess(res.message);
            } else {
              toastError(res.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      } catch (error) {
        toastError(error.message);
      }
   };
export const SendNewsNotification = (id) => {
    try {
        var raw = {
            types : ["news"],
            newsId : id
        }
      
        raw = JSON.stringify(raw);
  
        postMethod(`notification/sendNotification`, raw)
          .then((res) => {
            if (res.status === true) {
              console.log("NOTIFICATION RES : ", res);
              toastSuccess(res.message);
            } else {
              toastError(res.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      } catch (error) {
        toastError(error.message);
      }
   };
export const SendContentNotification = (id) => {
    try {
        var raw = {
            types : ["content"],
            contentId : id
        }
      
        raw = JSON.stringify(raw);
  
        postMethod(`notification/sendNotification`, raw)
          .then((res) => {
            if (res.status === true) {
              toastSuccess(res.message);
            } else {
              toastError(res.message);
            }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      } catch (error) {
        toastError(error.message);
      }
   };

export const SendFinanceNotification = (list) => {
  try {
    var raw = {
        types : [...list]
    }
  
    raw = JSON.stringify(raw);

    postMethod(`notification/sendNotification`, raw)
      .then((res) => {
        if (res.status === true) {
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  } catch (error) {
    toastError(error.message);
  }
   };
export const SendMetricsNotification = (list) => {
  try {
    var raw = {
        types : [...list]
    }
  
    raw = JSON.stringify(raw);

    postMethod(`notification/sendNotification`, raw)
      .then((res) => {
        if (res.status === true) {
          toastSuccess(res.message);
        } else {
          toastError(res.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  } catch (error) {
    toastError(error.message);
  }
   };

  export const sendNotification = (notiType, id) => {
    
    try {
      var raw = {
          types : [notiType]
      }
      if(id){
          raw = {...raw, productId: id.toString()}
      }
      
      raw = JSON.stringify(raw);

      postMethod(`notification/sendNotification`, raw)
        .then((res) => {
          if (res.status === true) {
            console.log("NOTIFICATION RES : ", res);
            toastSuccess(res.message);
          } else {
            toastError(res.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    } catch (error) {
      toastError(error.message);
    }
  };