import { useContext, useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod";
import { StateContext } from "../../../Context/StateContext";

function Metrics({onEditClick}) {

  const {setLoading,profileStatus} = useContext(StateContext);

    const [metricsDetails, setMetricsDetails] = useState(
        {
          totalEmployees:0,
          totalCustomers:0,
          clientRetentionPercent:0,
          npcScore:0,
        }
      )

    useEffect(() => {
        setLoading(true);

    window.scrollTo(0,0)
 
        getMethod(`metric/getMyMetricInformation`)
          .then((res) => {
            setLoading(false)
            if (res?.status) {
                if(res?.exists){

                console.log("Response metric : ", res.data)
                const updatedMetricsDetails = { ...metricsDetails,
                totalEmployees: res.data?.totalEmployees || 0,
                totalCustomers: res.data?.totalCustomers || 0,
                clientRetentionPercent: res.data?.clientRetentionPercent || 0,
                npcScore: res.data?.npcScore || 0,
              };
            setMetricsDetails(updatedMetricsDetails)

                }
            }
          })
          .catch((error) => {
            setLoading(false);
            // toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);
    return (
      <div className="p-5 lg:p-8 category-content-wrapper-div">
           {/* header */}
           <div className="xs:flex-col lg:flex lg:justify-between items-center">
  
                  <div className="flex flex-col">
                      <div className="font-medium text-[28px]">
                          Metrics
                      </div>
                      <div className="text-grey-2 lg:w-3/4 text-[16px]">
                      This is your opportunity to showcase some key metrics of your organization. You can also choose to not answer by choosing "N/A".
                      </div>
                  
                  </div>
                  {
    profileStatus !== "1" &&
                  <div className="flex lg:justify-end mt-5 lg:mt-0">
                      <button className="btn-sea-green-thick" onClick={onEditClick}>
                      Edit
                      </button>
                  </div>
                  }
  
          </div>
  
          <div className="flex flex-col gap-5">
  
                  <div className="xs:flex-col md:flex items-center">
  
                      <div className="md:w-1/3 font-medium text-black-1">
                        Total Employees
                      </div>
                      <div className="md:w-2/3 font-light">
                         {metricsDetails?.totalEmployees}
                      </div>
  
                  </div>
                  <div className="xs:flex-col md:flex items-center">
  
                      <div className="md:w-1/3 font-medium text-black-1">
                          Total Customers
                      </div>
                      <div className="md:w-2/3 font-light flex gap-5">
                          <div>
                          {metricsDetails?.totalCustomers}
                          </div>
                         
                      </div>
  
                  </div>
                  <div className="xs:flex-col md:flex items-center">
  
                      <div className="md:w-1/3 font-medium text-black-1">
                          Client Retention %
                      </div>
                      <div className="md:w-2/3 font-light">
                    {`${metricsDetails?.clientRetentionPercent} %`}
                      </div>
  
                  </div>
                  {/* <div className="flex items-center">
  
                      <div className="w-1/3 font-medium text-black-1">
                          NPS Score
                      </div>
                      <div className="w-2/3 font-light">
                      {metricsDetails?.npcScore}
                      </div>
  
                  </div> */}
              </div>
      </div>
    );
  }
  
  export default Metrics;
  