import {React, useContext, useState} from 'react'
import { ContentVideoContext } from '../../../../Context/ContentVideoContext';
import { StateContext } from '../../../../Context/StateContext';
import DeleteConfirmation from '../../modals/DeleteConfirmation';
import VideoUpload from '../../modals/VideoUpload';

export default function VideoCard({image, title, id, tag, remark, edit, onDelete, ...attributes}) {
    const {setVideoRemark} = useContext(ContentVideoContext)
    const {profileStatus} = useContext(StateContext)

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteClick = () => {
        // console.log("ID in product compo: ", id)
        // handleDelete(id)
        setShowDeleteModal(true);
      };

    const handleThumbnailClick = () => {
        setIsVideoPlaying(true);
        setOpenVideoModal(true)
      };
  return (
    
    <div className="flex flex-col bg-white rounded-lg border border-grey-7">
                {/* image */}
                <div className="h-[160px] relative rounded-t-lg bg-gradient-to-b from-grey-9 via-grey-9 to-grey-9 overflow-clip cursor-pointer"  onClick={handleThumbnailClick}>
                    <div className={`corner-ribbon ${tag === "featured"?'yellow': tag == 'firstCall' ? "green" :  "mustard"} flex gap-1 justify-center items-center`}>
                        <div className='rounded-full bg-white w-5 h-5 flex justify-center items-center'>
                            <img src="assets/podcast-icon.svg" alt="tag"/>
                        </div>
                        <div className='text-base font-medium capitalize'>
                            {tag}
                        </div>
                    </div>
                    <img src={image} alt="title" className="absolute rounded-t-lg h-full w-full object-cover mix-blend-overlay opacity-75" />
                </div>

                <div className='flex p-4 justify-between items-center'>

                    {/* CONTENT */}

                    <div className='flex flex-col gap-2 w-10/12'> 

                         {/* Description */}
                        

                             <div className="text-black-1 font-semibold leading-5 text-base overflow-hidden w-full max-w-prose line-clamp-2">
                            {title}
                            </div>

                    </div>

                    {/* MENU KEBAB */}

                    {
                          profileStatus !== "1" &&
                    <div className="flex align-center justify-center gap-3">

                        <div className=''>
                            <img className='cursor-pointer' src="/assets/edit.svg" 
                            onClick={() =>{ edit(true,id); setVideoRemark({type:'btn2', status:true, value: remark})}}/>
                            
                        </div>
                        <div className=''>
                        <img className='cursor-pointer' src="/assets/trash.svg" onClick={handleDeleteClick}/>
                            
                        </div>
                    </div>
                    }


                  
                  
                 
                        
                       
                </div>


      {isVideoPlaying && (
       <VideoUpload videoUrl={attributes?.videoUrl} closeHandler={()=>setIsVideoPlaying(false)}/>
      )}

{showDeleteModal && (
        <DeleteConfirmation
          setShowModal={setShowDeleteModal}
          deleteHandler={() => onDelete(id)}
        />
      )}

    </div>
  )
}
