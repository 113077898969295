import React, { useState } from 'react'
import useFirebaseAuth from '../../auth/useFirebaseAuth'
import { LoginQuery } from '../../utils/auth'
import { removeOnBoardCookie, setCompanyCookie, setOnBoardCookie } from '../../auth/cookies'
import { useNavigate } from 'react-router-dom'
import { toastError, toastSuccess, toastWarn } from '../../utils/Toasters'
import GoogleSignUpModal from './modals/GoogleSignUpModal'
// import { signOut } from 'firebase/auth'


function SignInWithGoogle({isRegistered}) {
    const url = process.env.REACT_APP_BASE_URL;
    // const url = "http://44.199.133.157:8090/v1/"

    const navigate = useNavigate()
    const {signInWithGoogle, signOut} = useFirebaseAuth();

    function handleRegisterApiCall(userCredential, details) {
      
        console.log(userCredential?.user.displayName)
  
        var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append(
                "Authorization",
                "Bearer " + userCredential.user.multiFactor.user.accessToken
              );
  
              var raw = JSON.stringify({
                name: details.name,
                ownerName: details.ownerName,
                phone:details.phone

              //   email: credentials.email ,
              });
  
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };
  
        fetch(url + "auth/register", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                  if (result.status) {
                    removeOnBoardCookie();
                    setOnBoardCookie(
                      userCredential.user.multiFactor.user.accessToken
                    );
                    setCompanyCookie(result)
                    toastSuccess(result.message)
                  console.log("Registration successful:", userCredential.user)
                  navigate('/company?activePage=dashboard')
                  } else {
                    toastError(result.message)
                  console.log("Error: ", result)
                  }
                })
                .catch((error) => {
                  toastError(error.message)
                  console.log("error", error)
                });
  
      }

    const signInWithGoogleHandler = async (e) =>{
     e.preventDefault();
        signInWithGoogle()
        .then(authUser => {
          console.log("AUTH USER: ", authUser)
  
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
  
          var raw = JSON.stringify({
            email : authUser.user.email
          })
          
    
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: raw
          };
        
          try{
  
              // fetch(url+"auth/checkUserExistence", requestOptions).then((response) => {return response.json()}).then((response)=>{

              //   console.log("Response from existence api: ", response)
                     
                  // if(response.status) {
                      
                    // if(response.exists){
                      LoginQuery(authUser.user.multiFactor.user.accessToken).then((response) => {
                        return response.json()
                      })
                      .then((response) => {
                        if(response.status){
                          setOnBoardCookie(authUser.user.multiFactor.user.accessToken)
                          setCompanyCookie(response)
                          navigate('/company?activePage=dashboard')
                        }else{
                        
                          if(response.code === 406){
                            isRegistered(false);
                          }else{
                            console.log("google sign in error : ", response.message)
                            toastError(response.message);
                            signOut();
                          }
                         
                        }   
                      }).catch((err)=> {
                        console.log("google sign in error : ", err.message)
                            // toastError(response.message);
                      })
  
  
                    // }else{
  
                      // let details = {
                      //   email: authUser.user.email,
                      //   ownerName: authUser.user.displayName,
                      //   name: "-",
                      //   phone: authUser.user.phoneNumber?authUser.user.phoneNumber:"-"
                      // }

                      // console.log("DETAILS: ", details)
  
                      // handleRegisterApiCall(authUser,details)
                      // toastWarn("Company doesn't exist. Please Sign Up.");
                      // signOut()
                      
  
                    // }
  
                  // }
                  // else{
  
                  //   console.log("RESPONSE.STATUS: ", response.status)
  
                  // }
              // }
              // )
          }catch(error){
            toastError(error.message)
            console.log("Error: " ,error)
          }
        })
        .catch((error) => {
            // setErrorBox(true)
            // setErrorMsg(error.message);
            // setLoading(false);
            toastError(error.message)
            console.log("Error: ", error)
        })
    }


  return (
    <div role="button" className="border-[1.5px]
     border-grey-14 w-full p-5 rounded-2xl flex 
    justify-center gap-3 items-center"
    onClick={signInWithGoogleHandler}>

        <div>
            <img src="assets/google-logo.svg" />
        </div>
        <div className="text-xl text-grey-10 font-base cursor-pointer">
            Sign in with Google
        </div>
    </div>
  )
}

export default SignInWithGoogle
