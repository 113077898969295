// export default function getFileTypeIcon(mimeType) {
//     switch (mimeType) {
//       // PDF
//       case 'application/pdf':
//         return <PDFIcon />;
  
//       // Excel Types
//       case 'application/vnd.ms-excel':
//       case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
//       case 'application/x-iwork-numbers-sffnumbers':
//       case 'application/vnd.apple.numbers':
//       case 'application/numbers':
//       case 'application/vnd.apple.numbers.13':
//       case 'application/x-numbers':
//       case 'text/csv':
//         return <ExcelIcon />;
  
//       // Word Types
//       case 'application/msword':
//       case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
//       case 'application/x-iwork-pages-sffpages':
//       case 'application/vnd.apple.pages':
//       case 'application/pages':
//       case 'application/vnd.apple.pages.13':
//       case 'application/x-pages':
//         return <WordIcon />;
  
//       // PowerPoint Types
//       case 'application/vnd.ms-powerpoint':
//       case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
//       case 'application/x-iwork-keynote-sffkey':
//       case 'application/vnd.apple.keynote':
//       case 'application/keynote':
//       case 'application/vnd.apple.keynote.13':
//       case 'application/x-key':
//         return <PowerPointIcon />;
  
//       // Default for unsupported types
//       default:
//         return <DefaultIcon />;
//     }
//   }
  
// Assuming you have SVG icons imported or accessible

 export default function FileIcon(fileExtension) {
  switch (fileExtension.toLowerCase()) {
    case 'pdf':
      return <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto">
      <path d="M22.3768 39.147C21.8861 39.147 21.5554 39.195 21.3848 39.243V42.3843C21.5874 42.4323 21.8408 42.4456 22.1901 42.4456C23.4674 42.4456 24.2541 41.8003 24.2541 40.7096C24.2541 39.7336 23.5768 39.147 22.3768 39.147ZM31.6754 39.179C31.1421 39.179 30.7954 39.227 30.5901 39.275V46.235C30.7954 46.283 31.1261 46.283 31.4248 46.283C33.6034 46.299 35.0221 45.099 35.0221 42.5603C35.0381 40.347 33.7448 39.179 31.6754 39.179Z" fill="#1CBAAB"/>
      <path d="M37.6667 5.3335H16.3333C14.9188 5.3335 13.5623 5.8954 12.5621 6.89559C11.5619 7.89579 11 9.25234 11 10.6668V53.3335C11 54.748 11.5619 56.1045 12.5621 57.1047C13.5623 58.1049 14.9188 58.6668 16.3333 58.6668H48.3333C49.7478 58.6668 51.1044 58.1049 52.1046 57.1047C53.1048 56.1045 53.6667 54.748 53.6667 53.3335V21.3335L37.6667 5.3335ZM25.6613 43.1735C24.8373 43.9468 23.6213 44.2935 22.2053 44.2935C21.9307 44.2987 21.6561 44.2826 21.384 44.2455V48.0482H19V37.5522C20.0759 37.3922 21.163 37.319 22.2507 37.3335C23.736 37.3335 24.792 37.6162 25.504 38.1842C26.1813 38.7228 26.64 39.6055 26.64 40.6455C26.6373 41.6908 26.2907 42.5735 25.6613 43.1735ZM35.8133 46.7868C34.6933 47.7175 32.9893 48.1602 30.9067 48.1602C29.6587 48.1602 28.776 48.0802 28.176 48.0002V37.5548C29.2523 37.3983 30.3391 37.3243 31.4267 37.3335C33.4453 37.3335 34.7573 37.6962 35.7813 38.4695C36.888 39.2908 37.5813 40.6002 37.5813 42.4802C37.5813 44.5148 36.8373 45.9202 35.8133 46.7868ZM45.6667 39.3868H41.5813V41.8162H45.4V43.7735H41.5813V48.0508H39.1653V37.4135H45.6667V39.3868ZM37.6667 24.0002H35V10.6668L48.3333 24.0002H37.6667Z" fill="#1CBAAB"/>
      </svg>
    //   <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="65"
    //   height="64"
    //   viewBox="0 0 65 64"
    //   fill="none"
    //   className="m-auto"
    // >
    //   <path
    //     d="M22.5447 39.146C22.0541 39.146 21.7234 39.194 21.5527 39.242V42.3833C21.7554 42.4313 22.0087 42.4447 22.3581 42.4447C23.6354 42.4447 24.4221 41.7993 24.4221 40.7087C24.4221 39.7327 23.7447 39.146 22.5447 39.146ZM31.8434 39.178C31.3101 39.178 30.9634 39.226 30.7581 39.274V46.234C30.9634 46.282 31.2941 46.282 31.5927 46.282C33.7714 46.298 35.1901 45.098 35.1901 42.5593C35.2061 40.346 33.9127 39.178 31.8434 39.178Z"
    //     fill="#1CBAAB"
    //   />
    //   <path
    //     d="M37.8327 5.33203H16.4993C15.0849 5.33203 13.7283 5.89393 12.7281 6.89413C11.7279 7.89432 11.166 9.25088 11.166 10.6654V53.332C11.166 54.7465 11.7279 56.1031 12.7281 57.1033C13.7283 58.1035 15.0849 58.6654 16.4993 58.6654H48.4994C49.9138 58.6654 51.2704 58.1035 52.2706 57.1033C53.2708 56.1031 53.8327 54.7465 53.8327 53.332V21.332L37.8327 5.33203ZM25.8273 43.172C25.0034 43.9454 23.7873 44.292 22.3713 44.292C22.0967 44.2972 21.8221 44.2812 21.55 44.244V48.0467H19.166V37.5507C20.2419 37.3907 21.329 37.3176 22.4167 37.332C23.902 37.332 24.958 37.6147 25.67 38.1827C26.3474 38.7214 26.806 39.604 26.806 40.644C26.8033 41.6894 26.4567 42.572 25.8273 43.172ZM35.9794 46.7854C34.8594 47.716 33.1553 48.1587 31.0727 48.1587C29.8247 48.1587 28.942 48.0787 28.342 47.9987V37.5534C29.4183 37.3968 30.5051 37.3228 31.5927 37.332C33.6113 37.332 34.9233 37.6947 35.9473 38.468C37.054 39.2894 37.7474 40.5987 37.7474 42.4787C37.7474 44.5134 37.0034 45.9187 35.9794 46.7854ZM45.8327 39.3854H41.7474V41.8147H45.566V43.772H41.7474V48.0494H39.3314V37.412H45.8327V39.3854ZM37.8327 23.9987H35.166V10.6654L48.4994 23.9987H37.8327Z"
    //     fill="#1CBAAB"
    //   />
    // </svg>;
    case 'doc':
    case 'docx':
    case 'pages':
      return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto">
      <path d="M32.496 38.8052C30.8506 38.8052 29.8906 40.3705 29.8906 42.4665C29.8906 44.5758 30.88 46.0665 32.512 46.0665C34.1573 46.0665 35.1013 44.4985 35.1013 42.4025C35.1013 40.4665 34.1733 38.8052 32.496 38.8052Z" fill="#1CBAAB"/>
      <path d="M37.3327 5.3335H15.9993C14.5849 5.3335 13.2283 5.8954 12.2281 6.89559C11.2279 7.89579 10.666 9.25234 10.666 10.6668V53.3335C10.666 54.748 11.2279 56.1045 12.2281 57.1047C13.2283 58.1049 14.5849 58.6668 15.9993 58.6668H47.9994C49.4138 58.6668 50.7704 58.1049 51.7706 57.1047C52.7708 56.1045 53.3327 54.748 53.3327 53.3335V21.3335L37.3327 5.3335ZM24.4127 46.5442C23.2767 47.4882 21.5513 47.9335 19.442 47.9335C18.1753 47.9335 17.282 47.8535 16.674 47.7735V37.1842C17.7644 37.0254 18.8655 36.9514 19.9673 36.9628C22.0153 36.9628 23.3433 37.3308 24.3807 38.1148C25.5007 38.9468 26.2047 40.2748 26.2047 42.1735C26.2047 44.2402 25.4527 45.6642 24.4127 46.5442ZM32.386 48.0002C29.186 48.0002 27.3167 45.5842 27.3167 42.5122C27.3167 39.2828 29.378 36.8695 32.562 36.8695C35.8714 36.8695 37.6794 39.3468 37.6794 42.3228C37.6767 45.8562 35.5327 48.0002 32.386 48.0002ZM44.7993 45.9682C45.5327 45.9682 46.3487 45.8055 46.8314 45.6162L47.1994 47.5175C46.7514 47.7415 45.7434 47.9815 44.434 47.9815C40.7087 47.9815 38.7887 45.6642 38.7887 42.5922C38.7887 38.9148 41.41 36.8695 44.674 36.8695C45.938 36.8695 46.8953 37.1255 47.3273 37.3495L46.8314 39.2855C46.1817 39.0186 45.4857 38.8827 44.7834 38.8855C42.8474 38.8855 41.3433 40.0535 41.3433 42.4535C41.3433 44.6108 42.6233 45.9682 44.7993 45.9682ZM37.3327 24.0002H34.666V10.6668L47.9994 24.0002H37.3327Z" fill="#1CBAAB"/>
      <path d="M20.2263 38.8345C19.685 38.8345 19.333 38.8825 19.125 38.9305V45.9838C19.333 46.0318 19.669 46.0318 19.9703 46.0318C22.1783 46.0478 23.6157 44.8345 23.6157 42.2585C23.6317 40.0185 22.3223 38.8345 20.2263 38.8345Z" fill="#1CBAAB"/>
      </svg>;
    case 'xls':
    case 'xlsx':
    case 'numbers':
    case 'csv':
      return <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto">
      <path d="M8.66602 13.3333V50.6667C8.66602 52.0812 9.22792 53.4377 10.2281 54.4379C11.2283 55.4381 12.5849 56 13.9993 56H51.3327C52.7472 56 54.1037 55.4381 55.1039 54.4379C56.1041 53.4377 56.666 52.0812 56.666 50.6667V13.3333C56.666 11.9188 56.1041 10.5623 55.1039 9.5621C54.1037 8.5619 52.7472 8 51.3327 8H13.9993C12.5849 8 11.2283 8.5619 10.2281 9.5621C9.22792 10.5623 8.66602 11.9188 8.66602 13.3333ZM27.3327 18.6667H48.666V24H27.3327V18.6667ZM27.3327 29.3333H48.666V34.6667H27.3327V29.3333ZM27.3327 40H48.666V45.3333H27.3327V40ZM16.666 18.6667H21.9993V24H16.666V18.6667ZM16.666 29.3333H21.9993V34.6667H16.666V29.3333ZM16.666 40H21.9993V45.3333H16.666V40Z" fill="#1CBAAB"/>
      </svg>;
    case 'ppt':
    case 'pptx':
    case 'key':
      return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto">
      <path d="M37.3303 5.3335H15.997C14.5825 5.3335 13.226 5.8954 12.2258 6.89559C11.2256 7.89579 10.6637 9.25234 10.6637 10.6668V53.3335C10.6637 54.748 11.2256 56.1045 12.2258 57.1047C13.226 58.1049 14.5825 58.6668 15.997 58.6668H47.997C49.4115 58.6668 50.768 58.1049 51.7682 57.1047C52.7684 56.1045 53.3303 54.748 53.3303 53.3335V21.3335L37.3303 5.3335ZM32.5597 42.5922C32.5577 42.4535 32.5577 42.4535 32.5597 42.5922C32.5577 42.4535 32.5597 42.5922 32.5577 42.4535C32.5558 42.3148 32.5597 42.5922 32.5577 42.4535L32.5597 42.5922ZM42.6637 37.1557C43.397 37.1557 42.1757 37.5388 42.6584 37.3495C42.2104 37.5735 43.973 37.1557 42.6637 37.1557C42.2317 36.9317 43.9277 37.1557 42.6637 37.1557L42.6584 37.3495C42.0087 37.0826 43.366 37.1529 42.6637 37.1557C40.4877 37.1557 42.6637 39.3131 42.6637 37.1557ZM37.3303 24.0002H34.6637V10.6668L47.997 24.0002H37.3303Z" fill="#1CBAAB"/>
      <path d="M16.9232 46.0005V35.3338H21.1315C21.9405 35.3338 22.6298 35.4883 23.1992 35.7974C23.7687 36.1029 24.2027 36.5283 24.5013 37.0734C24.8034 37.6151 24.9544 38.2401 24.9544 38.9484C24.9544 39.6567 24.8016 40.2817 24.4961 40.8234C24.1905 41.3651 23.7478 41.7869 23.168 42.089C22.5916 42.3911 21.8937 42.5422 21.0742 42.5422H18.3919V40.7349H20.7096C21.1437 40.7349 21.5013 40.6602 21.7826 40.5109C22.0673 40.3581 22.2791 40.1481 22.418 39.8807C22.5603 39.6099 22.6315 39.2991 22.6315 38.9484C22.6315 38.5942 22.5603 38.2852 22.418 38.0213C22.2791 37.754 22.0673 37.5474 21.7826 37.4015C21.4978 37.2522 21.1367 37.1776 20.6992 37.1776H19.1784V46.0005H16.9232ZM27.0059 46.0005V35.3338H31.2143C32.0233 35.3338 32.7125 35.4883 33.282 35.7974C33.8514 36.1029 34.2855 36.5283 34.5841 37.0734C34.8861 37.6151 35.0372 38.2401 35.0372 38.9484C35.0372 39.6567 34.8844 40.2817 34.5789 40.8234C34.2733 41.3651 33.8306 41.7869 33.2507 42.089C32.6743 42.3911 31.9764 42.5422 31.157 42.5422H28.4747V40.7349H30.7924C31.2264 40.7349 31.5841 40.6602 31.8653 40.5109C32.15 40.3581 32.3618 40.1481 32.5007 39.8807C32.6431 39.6099 32.7143 39.2991 32.7143 38.9484C32.7143 38.5942 32.6431 38.2852 32.5007 38.0213C32.3618 37.754 32.15 37.5474 31.8653 37.4015C31.5806 37.2522 31.2195 37.1776 30.782 37.1776H29.2611V46.0005H27.0059ZM36.6824 37.1932V35.3338H45.4429V37.1932H42.1772V46.0005H39.9481V37.1932H36.6824Z" fill="white"/>
      </svg>;
    default:
      return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto">
      <path d="M47.9994 58.6668C49.4138 58.6668 50.7704 58.1049 51.7706 57.1047C52.7708 56.1045 53.3327 54.748 53.3327 53.3335V21.3335L37.3327 5.3335H15.9993C14.5849 5.3335 13.2283 5.8954 12.2281 6.89559C11.2279 7.89579 10.666 9.25234 10.666 10.6668V53.3335C10.666 54.748 11.2279 56.1045 12.2281 57.1047C13.2283 58.1049 14.5849 58.6668 15.9993 58.6668H47.9994ZM34.666 10.6668L47.9994 24.0002H34.666V10.6668ZM18.666 21.3335H26.666V26.6668H18.666V21.3335ZM18.666 32.0002H45.3327V37.3335H18.666V32.0002ZM18.666 42.6668H45.3327V48.0002H18.666V42.6668Z" fill="#1CBAAB"/>
      </svg>;
  }
};
