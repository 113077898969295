
function NotificationConfirmation({yesHandler, noHandler, keyword}) {
console.log("notification modal coming...")
    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }
  return (
    <div className="modal-wrapper">

    <div className='flex bg-white w-10/12 lg:w-1/3 rounded-3xl box-shadow1 m-auto justify-center' style={modalStyle}>
        <div className="flex-col items-center m-5 w-full">

                <div className="flex-col items-center text-center mt-5">
                    {/* <p className="text-xl md:text-3xl font-bold">Are you sure?</p> */}
                    <div className="text-xl font-semibold">Would you like to notify your followers about the newly added {keyword}?</div>
                </div>
                <div className="flex gap-2 w-full justify-center mt-5 md:mt-5">

                    <div className="w-1/3">
                        <button  className="bg-aqua-1 text-white py-2 md:py-3 px-5 w-full rounded-lg text-sm md:text-lg"
                            onClick={yesHandler}>
                                Yes
                        </button>
                        
                        
                    </div>
                    <div className="w-1/3">
                        <button onClick={noHandler} className="bg-red text-white py-2 md:py-3 px-5 w-full rounded-lg text-sm md:text-lg">No</button>
                    </div>
                   

                </div>

        </div>

    </div>
    </div>
  );
}

export default NotificationConfirmation;
