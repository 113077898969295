import { useEffect } from "react";
import SingleAccount from "./SingleAccount";

function AccessControl() {
  const getData = () => {
    alert("API call")
  }
  // State Variables
  // useEffect(()=>{
  //   getData();
  // },[])

  const accounts = [{id:"1", email:"abc@yopmail.com", username: "John Doe", role:{name:"Editor"}}]

  return (
    <div className="bg-white shadow-lg flex flex-col gap-2 rounded-lg">
      {/* Search bar header */}
      <div className="rounded-t-lg flex gap-5 bg-aqua-2 p-2">
        <div className="text-lg text-sea-green font-bold">Request Details</div>

        <div className="rounded-md bg-white w-1/2 px-2 flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="#A1A1AA"
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            className="outline-none w-full"
            placeholder="Search with user name, company name..."
          />
        </div>
      </div>

      {/* Table */}
      <div className="h-[750px] overflow-auto hide-scrollbar">
        <table className="w-full overflow-x-scroll">
          <thead className="text-sea-green text-left border-b">
            <th className="p-2 min-w-[80px]">SNo.</th>
            <th className="p-2 min-w-[200px]">User Name</th>
            <th className="p-2 min-w-[150px] text-center">User Email</th>

            <th className="p-2 min-w-[200px] text-center">Joining Date</th>
            <th className="min-w-[230px] p-2 text-center">Role</th>
            <th className="min-w-[280px] p-2 text-center">Action</th>
          </thead>
          <tbody>
            {accounts&&accounts?.map((item,index)=>(
              <SingleAccount key={item?.id} item={item} index={index} getData={getData}/>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div></div>
    </div>
  );
}

export default AccessControl;
