import React, { useState } from "react";
import { useContext } from "react";
import { StateContext } from "../../../Context/StateContext";
import DeleteConfirmation from "../../general/modals/DeleteConfirmation";
import SuccessModal from "../../general/modals/SuccessModal";


function SingleAccount({ index, item, getData }) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [role, setRole] = useState({});
  const { setLoading } = useContext(StateContext);
const roles = [{name:"Viewer"},{name : "Editor"}]
  // Functions
  const deleteHandler = async() => {
    // setLoading(true);
    // try {
    //   let response = await deleteMethod(`admin/account/${item?.id}`);
    //   if (response?.status == true) {
    //     getData();
    //     toastSuccess("Account Deleted Successfully!");
    //   } else setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    //   toastError(error?.message);
    // }
    setShowSuccessModal(true)
  };

  const editHandler = async () => {
    // setLoading(true);
    // try {
    //   var raw = {
    //     roleId: role?.id,
    //   };
    //   let response = await patchMethod(`admin/account/${item?.id}`, raw, "raw");
    //   if (response?.status == true) {
    //     getData();
    //     toastSuccess("Permission Updated Successfully!");
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    //   toastError(error?.message);
    // }
  };
  return (
    <>
      <tr key={index + 1} className="border-b">
        <td className="py-3 px-2">{index + 1}.</td>
        <td className="py-3 px-2">{item?.username}</td>
        <td className="py-3 px-2 text-center">{item?.email}</td>
        <td className="py-3 px-2 text-center">
          {new Date(item?.createdAt)?.toDateString()}
        </td>
        <td className="p-2 text-center flex justify-center w-full">
          <div
            className="rounded-full p-1 px-5 bg-[#E7FAFF] flex justify-between w-full relative cursor-pointer"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div>{item?.role?.name}</div>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="mingcute:down-line" clip-path="url(#clip0_2299_351)">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7679 14.3981C11.596 14.5699 11.3629 14.6665 11.1199 14.6665C10.8768 14.6665 10.6437 14.5699 10.4718 14.3981L5.2862 9.21249C5.19864 9.12793 5.12881 9.02678 5.08077 8.91494C5.03273 8.80311 5.00744 8.68282 5.00638 8.56111C5.00532 8.43939 5.02852 8.31869 5.07461 8.20603C5.1207 8.09338 5.18877 7.99103 5.27483 7.90496C5.3609 7.81889 5.46325 7.75083 5.5759 7.70474C5.68856 7.65864 5.80927 7.63545 5.93098 7.63651C6.05269 7.63757 6.17298 7.66285 6.28482 7.7109C6.39665 7.75894 6.4978 7.82877 6.58236 7.91632L11.1199 12.4538L15.6574 7.91632C15.8302 7.74934 16.0618 7.65695 16.3021 7.65904C16.5425 7.66113 16.7724 7.75753 16.9424 7.92749C17.1123 8.09745 17.2087 8.32736 17.2108 8.5677C17.2129 8.80805 17.1205 9.0396 16.9535 9.21249L11.7679 14.3981Z"
                    fill="#222222"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2299_351">
                  <rect
                    width="22"
                    height="22"
                    fill="white"
                    transform="translate(0.119629)"
                  />
                </clipPath>
              </defs>
            </svg>

            {openDropdown && (
              <div className="flex flex-col bg-white rounded-lg shadow-md p-5 w-full absolute top-8 left-0 z-10">
                {roles &&
                  roles?.map((item) => (
                    <div
                      key={item?.id}
                      onClick={() => {
                        setRole(item);
                        setShowConfirmationModal(true);
                      }}
                      className="text-left rounded-md hover:bg-[#E7FAFF] p-2 cursor-pointer"
                    >
                      {item?.name}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </td>
        <td
          className="p-2 text-center text-sea-green font-bold cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          Remove User
        </td>
      </tr>
      {showModal && (
        <DeleteConfirmation
          setShowModal={setShowModal}
          deleteHandler={deleteHandler}
          message={
            "Would you like to permanently remove this role from your platform?"
          }
        />
      )}
      {showConfirmationModal && (
        <DeleteConfirmation
          setShowModal={setShowConfirmationModal}
          deleteHandler={editHandler}
          message={
            "Are you sure you want to change the role?"
          }
        />
      )}
      {
          showSuccessModal && <SuccessModal message = {"User has been removed successfully"} setShowModal={setShowSuccessModal}/>
      }
    </>
  );
}

export default SingleAccount;
