import { useState, useEffect } from "react";
import firebase from './firebaseConfig';
import { setToken, getToken, removeToken, removeUser, removeUserId, removeOnBoardCookie, setOnBoardCookie } from "./cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastError, toastSuccess } from "../utils/Toasters";

export default function useFirebaseAuth() {
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
   
    googleProvider.addScope("email"); // Already defined in your OAuth consent
    googleProvider.addScope("profile"); // Already defined in your OAuth consent
    googleProvider.addScope("openid"); // Standard OpenID Connect scope
    
    const formatAuthUser = (user) => ({
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        token: user.multiFactor.user.accessToken,
        phone: user.phoneNumber,
        photo: user.photoURL,
        verified: user.emailVerified
    });

    const authStateChanged = async (authState) => {
        if(!authState){ 
            removeToken()
            // removeOnBoardCookie()
            return;
        }
        // console.log("inside auth state / ", authState.multiFactor.user.accessToken)
        setToken(authState.multiFactor.user.accessToken)

    }
    const clear = () => {
        removeToken()
        removeOnBoardCookie()
        removeUserId()
        removeUser()
    }

    const createUserWithEmailAndPassword = (email, password) =>
        auth.createUserWithEmailAndPassword(email, password);
    
    const signInWithEmailAndPassword = (email, password) =>
        auth.signInWithEmailAndPassword(email, password);

    const signOut = () => 
        auth.signOut().then(clear);

    const forgotPassword = (email) => 
        auth.sendPasswordResetEmail(email);
    
    const signInWithGoogle = async () => {
      console.log("Auth : ", auth, " , Google provider : ", googleProvider)
      const response =  await auth.signInWithPopup(googleProvider);
      console.log("Response : ", response)
      return response
    }

        const verifier = () => {
            const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
              "recaptcha-container",
              {
                size: "invisible",
                callback: (response) => {
                  // console.log('Response', response);
                },
                "error-callback": (error) => {
                  toast.error(error, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                },
                "expired-callback": () => {
                  toast.error("Expired", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                },
              }
            );
            return recaptchaVerifier;
          };
    
    const signInWithPhoneNumber = (phone, appVerifier) =>
    firebase.auth().signInWithPhoneNumber(phone, appVerifier);

    const signInWithCustomToken = (firebaseToken) =>
    firebase.auth().signInWithCustomToken(firebaseToken);

    const changePassword = (newPassword) => {
      return new Promise((resolve, reject)=>{

        const user = firebase.auth().currentUser;
      
        user.updatePassword(newPassword).then(async() => {
  
          const newToken =  await user.getIdToken(true)
          setOnBoardCookie(newToken)
          console.log("NEW token inside firebase auth : ", newToken)
  
        toastSuccess("Password updated!")
        resolve(true);
        
        // Update successful.
        }).catch((error) => {
          reject(false)
            console.log("error change pwd: ", error)
            toastError(error.message)
        });
        
        } 

        

      )}
    

    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(authStateChanged);
        return () => {
          unsubscribe();
        };
    }, []);

    // returns function references for using the firebase authentication methods
    return {
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signOut,
        forgotPassword,
        signInWithGoogle,
        formatAuthUser,
        signInWithPhoneNumber,
        verifier,
        signInWithCustomToken,
        changePassword
    }
}