import React, { useContext, useEffect, useState } from 'react'
import { TextEditor } from '../../general/TextEditor'
import { getMethod, postMethod, postMethodTokenless } from '../../../utils/apiMethod';
import { toastError, toastSuccess } from '../../../utils/Toasters';
import LocalLoader from '../../general/LocalLoader';
import { StateContext } from '../../../Context/StateContext';
import { useNavigate } from 'react-router';


function PrivacyEdit({onBackClick}) {

const [policy, setPolicy] = useState('')
const [loading, setLoading] = useState('')

    useEffect(() => {
        setLoading(true);

       
          window.scrollTo(0,0)
 
    
        getMethod(`privacy`)
          .then((res) => {
            setLoading(false);
            if (res?.status) {
              console.log("privacy edit content : ", res.data);
    
              setPolicy(res.data.content);
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);

    const submitHandler = (e) => {
        setLoading(true);
    
        var raw = JSON.stringify({
          content: policy,
        });
    
        postMethod(`privacy`, raw)
          .then((res) => {
            // setLoading(false);
            if (res.status === true) {
              //   setLoading(false);
              console.log("RESPONSE : ", res);
             
              toastSuccess(res.data);
              onBackClick()
            }
            // else{
            //   toastError(res.message)
            // }
          })
          .catch((error) => {
            // setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      };
    
  return (
    <div className="flex flex-col gap-10 w-full">

    <div className="p-5 lg:p-8 category-content-wrapper-div gap-5">
      {/* header */}
      <div className="xs:flex-col lg:flex lg:justify-between lg:items-center">
        <div className='flex gap-3'>
          <div onClick={onBackClick} className="cursor-pointer">
            <img src="assets/arrow-back-circle.svg" alt="arrow-back" />
          </div>
          <div className="flex flex-col">
            <div className="font-medium text-[28px]">Privacy</div>
            <div className="text-grey-2 text-[16px]">
              Edit your policy details
            </div>
          </div>
        </div>
        <div className="flex lg:justify-end mt-5 lg:mt-0">
          <button className="btn-sea-green-thick" onClick={submitHandler}>
            Save
          </button>
        </div>
      </div>

      {/* Body */}

      {loading ? (
        <LocalLoader />
      ) : (
        <div className="flex flex-col gap-7 text-black-1 font-light text-base">
          <TextEditor
            placeholder={policy?.length > 0 ? "Edit policies" : "Add Policy"}
            value={policy}
            setValue={setPolicy}
          />
        </div>
      )}
    </div>
    </div>
  );
}

export default PrivacyEdit
