import { createContext, useEffect, useState } from "react";
import { getMethod } from "../utils/apiMethod";

export const StateContext = createContext();


const StateProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [newsLoading, setNewsLoading] = useState(false)
    const [privacyLoading, setPrivacyLoading] = useState(false)
    const [loadingTopContent, setLoadingTopContent] = useState(false)

    const [companyOwnerName, setCompanyOwnerName] = useState("")
    const [companyName, setCompanyName] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyBio, setCompanyBio] = useState("");

    const [refresh, setRefresh] = useState(true)
    const [refreshHeader, setRefreshHeader] = useState(true)

    const [topEngagedSection, setTopEngagedSection] = useState("");
    const [profileStatus, setProfileStatus] = useState("")


    useEffect(() => {
        getMethod(`company/getMyCompanyDetails`)
          .then((res) => {
            if (res?.status) {
              setProfileStatus(res?.company?.company?.profileStatus);
            }
           else{
            console.log("message: ", res.message);
           }
          })
          .catch((error) => {
            console.log("error.message: ", error.message);
          });
      }, []);
    
    return <StateContext.Provider value={{loading, setLoading, newsLoading, setNewsLoading, privacyLoading, setPrivacyLoading, loadingTopContent, setLoadingTopContent,
         companyLogo, setCompanyLogo, companyName, setCompanyName, setCompanyOwnerName, companyOwnerName, 
         companyBio, setCompanyBio, refresh, setRefresh, refreshHeader, setRefreshHeader, topEngagedSection, setTopEngagedSection, profileStatus, setProfileStatus}}>{children}</StateContext.Provider> 
} 

export default StateProvider